import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getAllReports } from '../../../services/corporatewellness/Service';
import { Link } from 'react-router-dom';

function ChallengeReportTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [reportType, setReportType] = useState('All');
    const [reportsData, setReportsData] = useState([])

    useEffect(() => {
        fetchReports();
    }, []);

    const fetchReports = async () => {
        try {
    
          const res = await getAllReports();
    
          if (res.data.status === 200) {
            setReportsData(res.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      };

    const columns = [
        {
            name: 'Generated By',
            selector: row => row.reportingEmail,
            sortable: true,
            cell: row => (
                <a href="#" dangerouslySetInnerHTML={{ __html: row.reportingEmail }}></a>
            ),
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        // {
        //     name: 'Phase',
        //     selector: row => row.reportPhase,
        //     sortable: true,
        // },
        {
            name: 'Generated On',
            selector: row => row.createdTime,
            sortable: true,
        },
        {
            name: 'Download Reports',
            cell: row => (
                <div>
                    <Link className="btn btn-Edit mx-2" to={row.url}>XLS</Link> 
                    {/* |
                    <button className="btn btn-Edit mx-2">PDF</button> */}
                </div>
            ),
        },
    ];

    const data = [
        {
            id: 1,
            generatedBy: 'GOQii Elite Challenge',
            reportType: 'Steps',
            challengeTime: 'Jan 31, 2024',
            challengeTimeEnd: 'Jan 31, 2024',
            reportPhase: '1',
            generatedOn: '26 Aug, 2024 02:43 pm',
        },
        {
            id: 2,
            generatedBy: 'GOQii Elite Challenge',
            reportType: 'Distance',
            challengeTime: 'Jan 31, 2024',
            challengeTimeEnd: 'Jan 31, 2024',
            reportPhase: '1',
            generatedOn: '26 Aug, 2024 02:43 pm',
        },
        {
            id: 3,
            generatedBy: 'GOQii Elite Challenge',
            reportType: 'App Usage Report',
            challengeTime: 'Jan 31, 2024',
            challengeTimeEnd: 'Jan 31, 2024',
            reportPhase: '1',
            generatedOn: '26 Aug, 2024 02:43 pm',
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setReportType('All');
    };

    const filteredData = reportsData.filter(item => {
        const matchesSearchQuery =
            item.reportType.toLowerCase().includes(searchQuery.toLowerCase()) 
            // ||
            // item.challengeTime.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesReportType = reportType === 'All' || item.reportType === reportType;

        return matchesSearchQuery && matchesReportType;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Campaign Name or Schedule Time"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={reportType}
                            onChange={e => setReportType(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">Select Report</option>
                            <option value="steps">Steps</option>
                            {/* <option value="Steps Milestone">Steps Milestone</option> */}
                            <option value="distance">Distance</option>
                            <option value="calories">Calories</option>
                            <option value="water">Water</option>
                            <option value="food">Food</option>
                            {/* <option value="Karma Earned">Karma Earned</option>
                            <option value="Karma Donated">Karma Donated</option> */}
                            <option value="activity">Activity</option>
                            {/* <option value="Team Engagement Report">
                            Team Engagement Report
                            </option> */}
                            {/* <option value="onboarding_status_report">
                            Onboarding Status Report
                            </option> */}
                            {/* <option value="Self Challenge Report">
                            Self Challenge Report
                            </option> */}
                            <option value="app_usage_report">App Usage Report</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default ChallengeReportTable;
