import React from 'react';
import './MDPAddNewService.css';

function MDPAddNewService() {

  return (
    <>
      <section className='MDPAddNewService'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Add New Service</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='MDP-Add-New-Service mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/1204_1731308805.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">This screen allows administrators to set up and define services available to users, including details such as service name, description, category, and any applicable fees or requirements. This setup provides a clear and organized catalog of services, ensuring users can easily browse, understand, and access offerings that suit their needs.</p>
              </div>
            </div>
          </div>

          <div className='MDP-Add-New-Service p-3'>
            <div className="row">
                <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Service Information</h1>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Service Name</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Service Name"/>
                </div>
                <div className='clearfix'></div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Service Description</label>
                    <textarea type="text" className="form-control input-field-ui" row="4" placeholder="Enter Service Description"></textarea>
                </div>
                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>

            <div className="row">
                <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Service Details</h1>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Service Category</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select Service Category</option>
                        <option value="1">Health Care</option>
                        <option value="2">Lab Tests</option>
                        <option value="3">Vaccination</option>
                        </select>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Service Provider</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select Provider</option>
                        <option value="1">Provider 1</option>
                        <option value="2">Provider 2</option>
                        <option value="3">Provider 3</option>
                        <option value="4">Provider 4</option>
                        <option value="5">Provider 5</option>
                        </select>
                </div>
                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>

            <div className="row">
                <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Service Cost and Duration</h1>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Service Cost (in $)</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Service Cost (in $)"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Duration</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Duration"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Time</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select</option>
                        <option value="1">Minutes</option>
                        <option value="2">Hours</option>
                        <option value="3">Days</option>
                        </select>
                </div>
                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>

            <div className="row">
                {/* <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Services and Type</h1>
                </div> */}
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Availability</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select</option>
                        <option value="1">Available</option>
                        <option value="2">Unavailable</option>
                        </select>
                </div>
                {/* <div className='col-md-12'>
                <hr className="my-3"/>
                </div> */}
            </div>

            <div className="row">
                <div className="col-md-12">
                <button type="button" className="btn btn-success uplaod-file-btn">Submit</button>
                </div>
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default MDPAddNewService;
