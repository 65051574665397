import React from 'react';
import './MDPDashboard.css';
import ActiveServicesRequests from './component/ActiveServicesRequests';
import KeyMetricsGraph from './component/KeyMetricsGraph';
import FinancialOverview from './component/FinancialOverview';


function MDPDashboard() {


  return (
    <>
      <section className='ReportsPrograms'>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">Dashboard</h1>
                    </div>
                    <div className='col-md-3 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by"
                                className="form-control input-field-ui ps-5"
                            />
                            <img src={require("../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://appcdn.goqii.com/storeimg/79623_1719989845.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Provides admins with an overview of program and condition-specific data, including key performance indicators (KPIs) on member engagement and service utilization.</p>
                        </div>
                    </div>
                </div>


                <div className="row">

                       <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title'>
                                    <span>Active Service Requests
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <ActiveServicesRequests/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Key Metrics
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <KeyMetricsGraph/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Financial Overview
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <FinancialOverview/>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                    <span>Provider List
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>
                               <div className='table-responsive mdp-table-table'>
                               <table class="table table-striped mb-0">
                               <thead>
                                    <tr>
                                        <th>Provider</th>
                                        <th>Status</th>
                                        <th>Update Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Provider A</td>
                                        <td>Active</td>
                                        <td>
                                            <select className='form-select input-field-ui-table'>
                                                <option value="notStarted" selected>Active</option>
                                                <option value="inProgress">Pending</option>
                                                <option value="completed" >Inactive</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Provider B</td>
                                        <td>Pending</td>
                                        <td>
                                            <select className='form-select input-field-ui-table'>
                                                <option value="notStarted">Active</option>
                                                <option value="inProgress" selected>Pending</option>
                                                <option value="completed" >Inactive</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Provider C</td>
                                        <td>Inactive</td>
                                        <td>
                                            <select className='form-select input-field-ui-table'>
                                                <option value="notStarted">Active</option>
                                                <option value="inProgress">Pending</option>
                                                <option value="completed" selected>Inactive</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                               </div>

                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>SLA Management
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <FinancialOverview/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Payments Due
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <FinancialOverview/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Completed Payments
                                    {/* <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger> */}
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalCallMadeCount}</span> */}
                                </h1>

                                <FinancialOverview/>
                            </div>
                        </div>


                </div>
        </div>
      </section>
    </>
  );
}

export default MDPDashboard;
