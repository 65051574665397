import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaInfoCircle, FaPlus, FaLanguage, FaArrowLeft } from "react-icons/fa";
import Modal from "./Modal";
import Select from "react-select";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { FaT } from "react-icons/fa6";
import HeaderDesc from "./HeaderDesc";
import { useNavigate } from 'react-router-dom';
import { FaCloudUploadAlt, FaTrash } from "react-icons/fa";
import {
  fetch_all_categories, fetch_all_tags, fetch_all_programs, fetch_all_conditions, fetch_all_pathways, save_post, fetch_single_article,
  update_post, sendApproval, log_data, generateAiBlog, fetchAuthors, getPresignedUrl, generateviewurl, updatePost, uploadHeaderImage, blogResavePublishedPost, addNewCategoryTag
} from "../../../services/cms/Service";
import { useParams } from "react-router-dom";
import FullHeightQuill from "./FullHeightQuill";
import axios from 'axios';

const EditBlog = ({
  blogContent,
  onCancel,
  tagsList = [],
  onAddCategory,
  onAddTag,
}) => {

  const { pid } = useParams();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  const quillRef = useRef(null);
  const [blog, setBlog] = useState({
    title: "",
    description: "",
    content: "",
    headerImage: "",
    contentType: "post",
    program: "",
    condition: "",
    pathway: "",
    ageGroups: [],
    gender: "",
    location: "",
    language: "Arabic",
    assignedMembers: [],
    author: "",
    categories: [],
    tags: [],
    publishedDate: new Date().toISOString().split("T")[0],
  });
  console.log(blog, "blog")
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState(tagsList);
  const [saveDraft, setSaveDraft] = useState(false);
  const [translatedContent, setTranslatedContent] = useState("");
  const [authors, setAuthors] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [pathways, setPathways] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [blogArticle, setBlogArticle] = useState({});
  const [globalError, setGlobalError] = useState({
    status: false,
    msg: ""
  });
  const [firstImageSrc, setFirstImageSrc] = useState('');

  const handleApproval = async () => {
    const { title, description, contentType, author, program, condition, pathway, headerImage, content } = blog;
    let categoryIds = blog.categories.map((category) => category.value);
    let tagIds = blog.tags.map((tag) => tag.value);
    let authorName;

    if (Array.isArray(blog.author)) {
      // If video.author is an array, use map
      let authorNameArray = blog.author.map((author) => author.label);
      authorName = JSON.stringify(authorNameArray)
    } else if (blog.author && blog.author.label) {
      // If blog.author is an object, access the label directly
      authorName = blog.author.label;
      console.log(authorName, "string")
    } else {
      // Handle case where author is not provided or empty
      authorName = "";
    }
    const imageUrl = `<img class="aligncenter size-large wp-image-16220" src="${headerImage}" alt="${title}" width="1024" height="682" />`;

    // Use a non-global regex to replace only the first <img> tag
    let cleanedContent = editorContent.replace(/<img[^>]*>/, imageUrl);

    console.log("Content after replacing first <img>:", cleanedContent);

    let data = { pid, title, description, contentType, headerImage, author: authorName ? authorName : "", content: cleanedContent, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };

    const resp = await updatePost(data);
    log_data({ pid: pid, action: "Update Post" })
    send_Approval({ pid: pid })
    navigate('/content-management/create-content');

    // setIsModalOpen(true);
  };

  const handleSavePublish = async () => {
    const { title, description, contentType, author, program, condition, pathway, headerImage, content } = blog;
    let categoryIds = blog.categories.map((category) => category.value);
    let tagIds = blog.tags.map((tag) => tag.value);
    let authorName;

    if (Array.isArray(blog.author)) {
      // If video.author is an array, use map
      let authorNameArray = blog.author.map((author) => author.label);
      authorName = JSON.stringify(authorNameArray)
    } else if (blog.author && blog.author.label) {
      // If blog.author is an object, access the label directly
      authorName = blog.author.label;
      console.log(authorName, "string")
    } else {
      // Handle case where author is not provided or empty
      authorName = "";
    }
    const imageUrl = `<img class="aligncenter size-large wp-image-16220" src="${headerImage}" alt="${title}" width="1024" height="682" />`;

    // Use a non-global regex to replace only the first <img> tag
    let cleanedContent = editorContent.replace(/<img[^>]*>/, imageUrl);

    console.log("Content after replacing first <img>:", cleanedContent);

    let data = { pid, title, description, contentType, headerImage, author: authorName ? authorName : "", content: cleanedContent, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };

    // const resp = await blogResavePublishedPost(data);
    log_data({ pid: pid, action: "Update Post" })
    // send_Approval({ pid: pid })
    navigate('/content-management/manage-content');

    // setIsModalOpen(true);
  };


  async function send_Approval() {
    try {
      let resp = await sendApproval({ pid: pid });
      console.log(resp, "response")
      log_data({ pid: pid, action: "send for Approval" })
      if (resp?.data?.data?.message === "Article Pending Approval") {
        setIsModalOpen(true);
        navigate('/content-management/content-status');
      }
    } catch (e) {

    }

  }

  async function fetch_Authors() {
    try {

      let response = await fetchAuthors();
      if (response) {
        console.log(response.data.data.details, "response")

        setAuthors(response.data.data.details)

      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetch_Authors()
  }, []);


  const suggestedCategories = [
    "Lifestyle",
    "Nutrition",
    "Mental Health",
    "Exercise",
  ];

  const [editorContent, setEditorContent] = useState("");

  // fetching categories and tags
  useEffect(() => {
    fetchPrograms();
    fetchCategories();
    fetchTags();
    getSinglePost();
  }, []);

  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      // Parse the HTML content
      const firstElement = contentRef.current.querySelector('p img');

      // Check if the first element is an image
      if (firstElement) {
        setFirstImageSrc(firstElement.src); // Extract the image source
        setBlog(prevBlog => ({ ...prevBlog, ["headerImage"]: firstElement.src }));
      }
    }
  }, [editorContent]);




  // Word limits
  const TITLE_LIMIT = 60;
  const DESCRIPTION_LIMIT = 300;
  const CONTENT_LIMIT = 1000;



  useEffect(() => {
    if (blogContent) {
      setBlog((prevBlog) => ({
        ...prevBlog,
        content: blogContent,
      }));
    }
  }, [blogContent]);

  const getWordCount = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    return text.trim().split(/\s+/).length;
  };

  const getCharacterCount = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    return text.length;
  };

  const handleInputChange = (field, value) => {

    if (field === "title" && getCharacterCount(value) > TITLE_LIMIT) return;
    if (field === "description" && getCharacterCount(value) > DESCRIPTION_LIMIT)
      return;

    // Special handling for tags
    if (field === "tags") {
      // Ensure value is always an array of objects with value and label properties
      const formattedValue = Array.isArray(value)
        ? value.map((tag) =>
          typeof tag === "string" ? { value: tag, label: tag } : tag
        )
        : [];
      setBlog((prevBlog) => ({ ...prevBlog, [field]: formattedValue }));
    } else if (field === 'categories' || field === 'tags') {
      setBlog(prevBlog => ({ ...prevBlog, [field]: value }));
    } else if (field === 'author') {
      setBlog(prevBlog => ({ ...prevBlog, [field]: value }));
    } else if (field === "program") {
      setBlog(prevBlog => ({ ...prevBlog, program: value }));
      fetchAllConditions(value);
    } else if (field === "condition") {
      setBlog(prevBlog => ({ ...prevBlog, condition: value }));
      fetchAllPathways(blog.program, value);
    }
    else {
      setBlog(prevBlog => ({ ...prevBlog, [field]: value }));
    }
  };



  const handleHeaderImageUpload = async (e) => {
    if (e != null) {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('headerFile', file);
        const response = await uploadHeaderImage(formData);
        const url = response.data.data.link;
        console.log(url, "url")
        if (url) {
          handleInputChange("headerImage", url);
          updateEditorContentWithNewHeaderImage(url);
        }
      } else {
        handleInputChange("headerImage", "");
      }
    }
  }
  const updateEditorContentWithNewHeaderImage = (newHeaderImageUrl) => {

    let updatedContent = editorContent;
    console.log(updatedContent, "updatedContent")

    // if (!isHeaderImageInContent) {
    // Replace the old header image with the new one, or add it if no image exists
    const imageUrl = `<img class="aligncenter size-large wp-image-16220" src="${newHeaderImageUrl}" alt="${blog.title}" width="1024" height="682" /><br/>`;
    console.log(imageUrl, "imageUrl")
    // Check if an image tag is already present in the content
    const isImageTagPresent = /<img[^>]*>/.test(editorContent);

    // Check if any image tag already has the correct alt attribute
    const isAltPresent = /<img[^>]*alt="[^"]*${article.post_title}[^"]*"[^>]*>/.test(editorContent);

    // If no image tag is present, add the new image at the beginning of the content
    if (!isImageTagPresent && !isAltPresent) {
      updatedContent = imageUrl + updatedContent;
    } else {
      // If an image tag is present, replace the existing image with the new header image
      updatedContent = updatedContent.replace(/<img[^>]*src="[^"]*"[^>]*>/, imageUrl);
    }
    console.log(updatedContent, "updatedContent")
    // Now you can set the updated content to the state or wherever necessary
    setEditorContent(updatedContent);
    setBlog(prevBlog => ({ ...prevBlog, content: updatedContent }));
    // }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async () => {
    console.log("Submitting blog:", blog);
    const { title, description, contentType, author, program, condition, pathway, headerImage, content } = blog;
    let categoryIds = blog.categories.map((category) => category.value);
    let tagIds = blog.tags.map((tag) => tag.value);
    let authorName;

    if (Array.isArray(blog.author)) {
      let authorNameArray = blog.author.map((author) => author.label);
      authorName = JSON.stringify(authorNameArray);
    } else if (blog.author && blog.author.label) {
      authorName = blog.author.label;
    } else {
      authorName = "";
    }

    const imageUrl = `<img class="aligncenter size-large wp-image-16220" src="${headerImage}" alt="${title}" width="1024" height="682" />`;

    // Use a non-global regex to replace only the first <img> tag
    let cleanedContent = editorContent.replace(/<img[^>]*>/, imageUrl);

    console.log("Content after replacing first <img>:", cleanedContent);

    let data = {
      pid,
      title,
      description,
      contentType,
      headerImage,
      author: authorName ? authorName : "",
      content: cleanedContent,
      programId: program ? parseInt(program) : 0,
      conditionId: condition ? parseInt(condition) : 0,
      pathwayId: pathway ? parseInt(pathway) : 0,
      categories: JSON.stringify(categoryIds),
      tags: JSON.stringify(tagIds)
    };

    const resp = await updatePost(data);
    log_data({ pid: pid, action: "Update Post" });
    navigate('/content-management/create-content');
  };

  const handleDraft = () => {
    console.log("Saving the blog as draft:", blog);
    setIsModalOpen(true);
    setSaveDraft(true);
  };


  const handleCreateCategory = () => {
    if (newCategory) {
      const createdCategory = onAddCategory({
        title: newCategory,
        description: newSubCategory || '',
        image: null // You might want to handle image upload separately
      });
      setBlog(prevBlog => ({
        ...prevBlog,
        categories: [...prevBlog.categories, { value: createdCategory.id, label: createdCategory.title }]
      }));
      setNewCategory('');
      setNewSubCategory('');
      setShowCategoryModal(false);
    }
  };

  const handleCreateTag = (inputValue) => {
    const createdTag = onAddTag({ title: inputValue, description: '' });
    return { value: createdTag.id, label: createdTag.title };
  };

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection(true);
          quill.insertEmbed(range.index, "image", reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  };


  // useEffect(() => {
  //   console.log("Editor content:", editorContent);
  // }, [editorContent]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "align",
    "link",
    "image",
  ];

  const handleEditorChange = (content) => {
    console.log(content, "handleEditorChange")
    if (getWordCount(content) <= CONTENT_LIMIT) {
      setEditorContent(content);
    }
  };

  const handlePreview = async () => {
    const { title, description, contentType, author, program, condition, pathway, headerImage, content } = blog;
    let categoryIds = blog.categories.map((category) => category.value);
    let tagIds = blog.tags.map((tag) => tag.value);
    let authorName;

    if (Array.isArray(blog.author)) {
      // If video.author is an array, use map
      let authorNameArray = blog.author.map((author) => author.label);
      authorName = JSON.stringify(authorNameArray)
    } else if (blog.author && blog.author.label) {
      // If blog.author is an object, access the label directly
      authorName = blog.author.label;
      console.log(authorName, "string")
    } else {
      // Handle case where author is not provided or empty
      authorName = "";
    }
    const imageUrl = `<img class="aligncenter size-large wp-image-16220" src="${headerImage}" alt="${title}" width="1024" height="682" />`;

    // Use a non-global regex to replace only the first <img> tag
    let cleanedContent = editorContent.replace(/<img[^>]*>/, imageUrl);

    console.log("Content after replacing first <img>:", cleanedContent);
    let data = { pid, title, description, contentType, headerImage, author: authorName ? authorName : "", content: cleanedContent, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };
    if (!type) {
      const resp = await updatePost(data);
    }
    log_data({ pid: pid, action: "Preview Post" })
    navigate(`/content-management/preview-post/${pid}`);
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleHeaderImageUpload({ target: { files: [file] } });
  };

  const handleKeyPress = (event) => {
    console.log('Key pressed:', event.key);
  };

  // fetch blog content categories 
  async function fetchCategories() {
    try {
      let response = await fetch_all_categories();

      if (response.data?.code === 200) {
        setCategories(response?.data?.data?.categories || []);
      } else {
        throw new Error("Failed to fetch categories");
      }
    } catch (e) {

    }
  }

  // fetch list of all tags 
  async function fetchTags() {
    try {
      let response = await fetch_all_tags();

      if (response.data?.code === 200) {
        const dataArray = JSON.parse(response?.data?.data?.tags)
        setTags(dataArray || []);
      } else {
        throw new Error("Failed to fetch tags");
      }
    } catch (e) {

    }
  }

  // fetch list of all programs 
  async function fetchPrograms() {
    try {
      let response = await fetch_all_programs();

      if (response.data?.code === 200) {
        setPrograms(response?.data?.data?.programs || []);
      } else {
        throw new Error("Failed to fetch programs");
      }
    } catch (e) {

    }
  }

  // fetch all conditions 
  async function fetchAllConditions(programId) {
    try {
      let response = await fetch_all_conditions(programId);

      if (response.data?.code === 200) {
        setConditions(response?.data?.data?.conditions || []);
      } else {
        throw new Error("Failed to fetch conditions");
      }
    } catch (e) {

    }
  }

  // fetch all pathways 
  async function fetchAllPathways(programId, conditionId) {
    try {
      let response = await fetch_all_pathways({ programId, conditionId });

      if (response.data?.code === 200) {
        setPathways(response?.data?.data?.pathways || []);
      } else {
        throw new Error("Failed to fetch conditions");
      }
    } catch (e) {

    }
  }
  const [translating, setTranslating] = useState(false);
  const handleTranslate = async () => {
    setTranslating(true)
    // Call your translation API or function here
    // const response = await generateAiBlog({ message: `translate ${editorContent} in Arabic without removing HTML tags` });
    const response = await generateAiBlog({
      message: `Please translate the following text to ${blog.language} only and don't add anything extra.. Format the response using HTML tags to ensure it can be displayed properly on a web page. Use the following HTML elements where appropriate:

        - **Bold Text**: <strong>your text here</strong>
        - **Underlined Text**: <u>your text here</u>
        - **Line Breaks**: <br> (insert line breaks where appropriate)
        - **Paragraphs**: <p>your text here</p>
        - **Headings**: <h1>, <h2>, <h3>, etc. (for different heading levels)
        - **Lists**: 
            - Unordered lists: <ul><li>item</li></ul>
            - Ordered lists: <ol><li>item</li></ol>
        - **Links**: <a href="URL">your link text</a>
        - **Images**: <img src="image URL" alt="description">
        
        Make sure to use these tags appropriately to enhance the readability and structure of the response.
        
        **Topic:** ${editorContent}?`
    });
    if (response?.data?.data?.text) {
      if (blog.language == 'Arabic') {
        const cleanResponseData = response?.data?.data?.text
          .replace(/^```html\n/, '') // Existing replacement
          .replace(/<p>\s*```.*?<\/p>/g, '')
          .replace(/\r\n/g, '<br>')
          .replace(/<p>/g, '<p class="ql-align-right">')
          .replace(/<h1>/g, '<h1 class="ql-align-right">')
          .replace(/<h2>/g, '<h2 class="ql-align-right">')
          .replace(/<h3>/g, '<h3 class="ql-align-right">')
          .replace(/<h4>/g, '<h4 class="ql-align-right">')
          .replace(/<h5>/g, '<h5 class="ql-align-right">')
          .replace(/<h6>/g, '<h6 class="ql-align-right">');
        setTranslatedContent(cleanResponseData);
      } else {
        const cleanResponseData = response?.data?.data?.text
          .replace(/^```html\n/, '') // Existing replacement
          .replace(/<p>\s*```.*?<\/p>/g, '')
          .replace(/\r\n/g, '<br>')
          .replace(/\n/g, '<br>')
        setTranslatedContent(cleanResponseData);
      }
    };
    setTranslating(false)
  }

  console.log(translatedContent, "translatedContent")

  function escapeRegExp(string) {
    return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');
  }

  console.log(editorContent, "editorContent")
  async function getSinglePost() {
    try {
      let response = await fetch_single_article({ pId: pid });


      if (response?.data?.code == 200) {
        const { article } = response?.data?.data;
        setBlogArticle(article);
        fetchAllConditions(article.programId);
        fetchAllPathways(article.programId, article.conditionId);

        const formattedAuthor = [{ label: article.post_author }];
        const resHeader = await generateviewurl({ presignedUrl: article.post_header });
        const thumbnailUrl = resHeader.data.data;
        const imageUrl = `<img class="aligncenter size-large wp-image-16220" src="${article.post_header}" alt="${article.post_title}" width="1024" height="682" /><br/>`;

        // Check if any <img> tag is already present in `post_content`
        const isImageTagPresent = /<img[^>]*>/.test(article.post_content);
        console.log(article.post_content, "article.post_content");
        console.log(isImageTagPresent, "isImageTagPresent");

        // Check if any image tag already has the correct alt attribute

        const safeTitle = escapeRegExp(article.post_title);
        console.log(safeTitle, "safeTitle")
        const isAltPresent = new RegExp(`<img[^>]*alt="[^"]*${safeTitle}[^"]*"[^>]*>`).test(article.post_content);
        // const isAltPresent = /<img[^>]*alt="[^"]*${article.post_title}[^"]*"[^>]*>/.test(article.post_content);
        console.log(isAltPresent, "isAltPresent");

        let cleanResponseData;

        if (article.post_header && !isAltPresent) {
          // If post_header is present and alt attribute is not found, add imageUrl at the beginning
          cleanResponseData = imageUrl + article.post_content;
          console.log("reached0")
        }
        else if (article.post_header && isImageTagPresent && !isAltPresent) {
          // If post_header is present, image tag is present but alt attribute is missing, add new imageUrl at the beginning
          cleanResponseData = article.post_content
          console.log("reached");
        }
        else if (article.post_header && isImageTagPresent && isAltPresent) {
          // If alt attribute is already present, just leave the content as is
          cleanResponseData = article.post_content
          console.log("reached1");
        } else {
          // If post_header is blank, retain content without changes
          cleanResponseData = article.post_content
          console.log("reached2")
        }
        // Apply existing replacements
        cleanResponseData = cleanResponseData
          .replace(/^```html\n/, '')  // Existing replacement
          .replace(/\r\n/g, '<br/>');

        console.log(cleanResponseData, "cleanResponseData");
        setEditorContent(cleanResponseData);
        setBlog({
          title: article.post_title,
          description: article.post_excerpt,
          content: cleanResponseData,
          headerImage: thumbnailUrl,
          contentType: "post",
          program: article.programId,
          condition: article.conditionId,
          pathway: article.pathwayId,
          ageGroups: [],
          gender: "",
          location: "",
          language: "Arabic",
          assignedMembers: [],
          author: formattedAuthor,
          categories: article.categories_obj,
          tags: article.tags_obj,
          publishedDate: new Date().toISOString().split("T")[0],
        });
      }
      console.log(response);
    } catch (e) {
      if (e.response.status === 422) {
        setGlobalError({
          status: true,
          msg: e.response?.data?.data?.msg || "Resource Not Found"
        })
      }
    }
  }
  const [inputValue, setInputValue] = useState('');
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue) {
      // Create a new tag with 'name', 'slug', and 'term_id'

      const tagExists = tags.some(tag => tag.name.toLowerCase() === inputValue.toLowerCase());
      console.log(tagExists, "tagExists")
      if (tagExists) {
        console.log('Tag already exists');
        return; // Stop the function if the tag already exists
      }
      const newTag = { value: inputValue, label: inputValue };

      // Add the new tag to the existing tags array
      const updatedTags = [...blog.tags, newTag]; // Spread the existing tags and add the new one

      // Update the tags state
      handleInputChange('tags', updatedTags);

      // Update the external tags list if necessary
      setTags(prevTags => [...prevTags, newTag]); // Update with the new tag

      // Optionally call an external function to handle the new tag
      add_New_Tag(inputValue);

      // Clear the input
      setInputValue('');
    }
  };

  async function add_New_Tag(name) {
    try {

      let response = await addNewCategoryTag({ type: "tag", name: name });
      if (response) {
        console.log(response, "response")

      } else {
        throw new Error("Failed to fetch blogs");
      }
    } catch (e) {
      console.error(e);
    }
  }




  return (
    <div className="tw-bg-white tw-rounded-lg tw-shadow-xl tw-pb-20">
      {
        globalError.status ?
          <div>
            <h2>{globalError.msg}</h2>
          </div> :

          <>
            <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-8 tw-text-blue-950">
              Edit Content
            </h2>
            <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-my-4 tw-relative">
              <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                <span className="tw-text-sm tw-mr-2">Title</span>
              </label>
              <input
                type="text"
                placeholder="Enter title"
                className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-pr-16"
                value={blog.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
              />
              <span className="tw-absolute tw-bottom-2 tw-right-4 tw-text-sm tw-text-gray-500">
                {getCharacterCount(blog.title)}/{TITLE_LIMIT}
              </span>
            </div>

            <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-my-4 tw-relative">
              <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                <span className="tw-text-sm tw-mr-2">Description</span>
              </label>
              <input
                type="text"
                placeholder="Enter description"
                className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-pr-16"
                value={blog.description}
                onChange={(e) => handleInputChange("description", e.target.value)}
              />
              <span className="tw-absolute tw-bottom-2 tw-right-4 tw-text-sm tw-text-gray-500">
                {getCharacterCount(blog.description)}/{DESCRIPTION_LIMIT}
              </span>
            </div>

            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-mb-6">
              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Content Type</span>
                </label>
                <select
                  className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                  value={blog.contentType}
                  onChange={(e) => handleInputChange("contentType", e.target.value)}
                >
                  <option disabled value="">
                    Select Content Type
                  </option>
                  <option value="post">Blog</option>
                  <option disabled>Workout Video</option>
                  <option disabled>Awareness Video</option>
                </select>
              </div>

              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Author</span>
                </label>
                <Select
                  isMulti
                  options={authors.map(cat => ({ label: cat.user_nicename }))}
                  value={blog.author}
                  onChange={(selectedOptions) => handleInputChange('author', selectedOptions)}
                  className="tw-flex-grow"
                  placeholder="Select Author"
                />
              </div>

              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Categories</span>
                </label>
                <div className="tw-flex tw-items-center tw-space-x-2">
                  <Select
                    isMulti
                    options={categories.map(cat => ({ value: cat.term_id, label: cat.name }))}
                    value={blog.categories}
                    onChange={(selectedOptions) => handleInputChange('categories', selectedOptions)}
                    className="tw-flex-grow"
                    placeholder="Select categories"
                  />
                  <Tooltip title="Add new category" position="top" animation="scale">
                    <button
                      className="tw-p-2 tw-bg-blue-100 tw-rounded-full hover:tw-bg-blue-200 tw-cursor-pointer tw-transition-all tw-duration-300"
                      // onClick={onAddCategory}
                      onClick={() => setShowCategoryModal(true)}
                    >
                      <FaPlus />
                    </button>
                  </Tooltip>
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Tags</span>
                </label>
                {/* <Select
                  isMulti
                  options={tags.map(tag => ({ value: tag.term_id, label: tag.name }))}
                  value={blog.tags}
                  onChange={(selectedOptions) => handleInputChange('tags', selectedOptions)}
                  className="tw-w-full"
                  placeholder="Add Tags"
                  onCreateOption={handleCreateTag}
                  isCreatable={true}
                /> */}
                <Select
                  isMulti
                  options={tags.map(tag => ({ value: tag.term_id, label: tag.name }))}
                  value={blog.tags}
                  onChange={(selectedOptions) => handleInputChange('tags', selectedOptions)}
                  className="tw-w-full"
                  placeholder="Add Tags"
                  inputValue={inputValue}  // Bind input value to state
                  onInputChange={(value) => setInputValue(value)}  // Track input value
                  onKeyDown={handleKeyDown}  // Trigger on "Enter" key press
                  onCreateOption={(inputValue) => {
                    const newTag = { title: inputValue, description: '' };

                    return { value: newTag.id, label: newTag.title };
                  }}
                  isCreatable={true}
                />
              </div>
            </div>
            {/* </div> */}

            <div className="tw-grid tw-grid-cols-3 tw-gap-2">
              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Program</span>
                </label>
                <select
                  className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                  value={parseInt(blog.program) === 0 ? "" : blog.program}
                  onChange={(e) => handleInputChange("program", e.target.value)}
                >
                  <option disabled value="">
                    Select Program
                  </option>
                  {
                    programs.map((program) => (
                      <option key={program.programId} value={program.programId}>{program.programName} </option>
                    ))
                  }

                </select>
              </div>

              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Condition</span>
                </label>
                <select
                  className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                  value={parseInt(blog.condition) === 0 ? "" : blog.condition}
                  onChange={(e) => handleInputChange("condition", e.target.value)}
                >
                  <option disabled value="">
                    Select Condition
                  </option>
                  {
                    conditions.map((condition) => (
                      <option key={condition.conditionId} value={condition.conditionId}>{condition.conditionName}</option>
                    ))
                  }
                </select>
              </div>

              <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                  <span className="tw-text-sm tw-mr-2">Pathway</span>
                </label>
                <select
                  className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                  value={parseInt(blog.pathway) === 0 ? "" : blog.pathway}
                  onChange={(e) => handleInputChange("pathway", e.target.value)}
                >
                  <option disabled value="">
                    Select Pathway
                  </option>
                  {
                    pathways.map((pathway) => (
                      <option key={pathway.pathwayId} value={pathway.pathwayId}>{pathway.pathwayName}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="tw-flex tw-flex-col tw-border tw-py-4 tw-px-4 tw-rounded-lg tw-relative tw-my-6 tw-bg-gray-50">
              <label className="tw-flex tw-items-center tw-mb-2">
                <span className="tw-text-sm tw-font-medium tw-text-gray-900">
                  Header Image (Up to 700kb, 1280x720)
                </span>
              </label>
              <div
                className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-border-2 tw-border-dashed tw-rounded-lg tw-p-6 tw-transition-colors tw-duration-300 ${isDragging
                  ? "tw-border-blue-500 tw-bg-blue-50"
                  : "tw-border-gray-300"
                  }`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {blog.headerImage ? (
                  <div className="tw-relative tw-w-full tw-h-40 tw-mb-4">
                    <img
                      src={blog.headerImage}
                      alt="Header"
                      className="tw-w-full tw-h-full tw-object-cover tw-rounded-lg tw-shadow-md"
                    />
                    <button
                      onClick={() => handleHeaderImageUpload(null)}
                      className="tw-absolute tw-top-2 tw-right-2 tw-bg-red-500 tw-text-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
                    >
                      <FaTrash />
                    </button>
                  </div>
                ) : (
                  <FaCloudUploadAlt className="tw-text-4xl tw-text-gray-400 tw-mb-2" />
                )}
                <p className="tw-text-sm tw-text-gray-600 tw-mb-2">
                  {blog.headerImage
                    ? "Drag & drop to replace"
                    : "Drag & drop your image here"}
                </p>
                <p className="tw-text-xs tw-text-gray-500 tw-mb-4">or</p>
                <label className="tw-cursor-pointer tw-bg-blue-500 tw-text-white tw-py-2 tw-px-4 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300">
                  Choose File
                  <input
                    type="file"
                    className="tw-hidden"
                    onChange={handleHeaderImageUpload}
                    accept="image/*"
                  />
                </label>
              </div>
              {blog.headerImage && (
                <p className="tw-text-xs tw-text-gray-500 tw-mt-2 tw-text-center">
                  Image uploaded successfully
                </p>
              )}
            </div>

            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-gap-4 lg:tw-gap-8 tw-justify-between tw-py-4">
              {/* English Editor Area */}
              <div className="tw-bg-base-200 tw-p-4 tw-rounded-lg tw-flex-grow tw-bg-white tw-border tw-w-full lg:tw-w-5/12 tw-overflow-hidden tw-flex tw-flex-col" style={{ height: '800px' }}>
                <h1 className="tw-text-xl tw-font-semibold tw-mb-2">English</h1>
                <div className="tw-flex-grow tw-overflow-hidden">
                  <div ref={contentRef} className="tw-flex-grow tw-overflow-hidden">
                    <FullHeightQuill
                      value={editorContent}
                      onChange={handleEditorChange}
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                </div>
                <span className="tw-text-sm tw-text-gray-500 tw-mt-2">
                  {getWordCount(editorContent)}/{CONTENT_LIMIT} words
                </span>
              </div>

              {/* Language Selector and Translate Button */}
              <div className="tw-hidden lg:tw-flex tw-flex-col tw-items-center tw-justify-center">
                <div className="tw-w-24 tw-mb-2 tw-p-0.5 tw-flex tw-flex-col tw-items-center tw-gap-2 tw-bg-white tw-rounded-lg tw-border">
                  <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                    <span className="tw-text-sm tw-mr-2">Language</span>
                  </label>
                  <select
                    className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                    value={blog.language}
                    onChange={(e) => handleInputChange("language", e.target.value)}
                  >
                    <option value="Arabic">Arabic</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Marathi">Marathi</option>
                    <option value="French">French</option>
                    <option value="Spanish">Spanish</option>
                    <option value="German">German</option>
                    <option value="Dutch">Dutch</option>
                    <option value="Swedish">Swedish</option>
                    <option value="Italian">Italian</option>
                    <option value="Russian">Russian</option>
                    <option value="Japanese">Japanese</option>
                    <option value="Chinese">Chinese</option>
                    {/* <option value="English">English</option> */}
                  </select>
                  <div
                    className="tw-bg-blue-700 tw-text-white tw-px-2 tw-py-1 tw-rounded-lg hover:tw-bg-blue-400 tw-cursor-pointer tw-transition-all tw-duration-300 tw-w-20 tw-h-16 tw-flex tw-justify-center tw-items-center tw-flex-col"
                    onClick={handleTranslate} // Ensure you define handleTranslate to trigger translation logic
                  >
                    <FaLanguage className="tw-text-xl" />
                    <span>Translate</span>
                  </div>
                </div>
              </div>
              {/* Arabic Editor Area */}
              <div className="tw-bg-base-200 tw-p-4 tw-rounded-lg tw-flex-grow tw-bg-white tw-border tw-w-full lg:tw-w-5/12 tw-overflow-hidden tw-flex tw-flex-col" style={{ height: '800px' }}>
                <h1 className="tw-text-xl tw-font-semibold tw-mb-2">{blog.language}</h1>
                <div className="tw-flex-grow tw-overflow-hidden">
                  <FullHeightQuill
                    value={translatedContent}
                    onChange={(content) => setTranslatedContent(content)}
                    modules={modules}
                    formats={formats}
                  />
                </div>
              </div>
              <span className="tw-text-sm tw-text-gray-500 tw-mt-2">
                {getWordCount(translatedContent)}/{CONTENT_LIMIT} words
              </span>
            </div>
            <div className="tw-flex tw-justify-between tw-items-center">
              <button
                className="tw-flex tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                onClick={e => navigate('/content-management/create-content')}
              >
                <FaArrowLeft className="tw-mr-2" /> Back
              </button>
              <div className="tw-space-x-2">
                {type === 'approval' && (
                  <>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={handlePreview}
                    >
                      Preview
                    </button>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={handleSubmit}
                    >
                      Save as Draft
                    </button>
                  </>
                )}

                {type === 'publish' && (
                  <>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={(e) => handleApproval()}
                    >
                      Submit for Approval
                    </button>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={handlePreview}
                    >
                      Preview
                    </button>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={(e) => handleSavePublish()}
                    >
                      Update Post
                    </button>
                  </>
                )}

                {(type !== 'approval' && type !== 'publish') && (
                  <>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={handlePreview}
                    >
                      Preview
                    </button>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={handleSubmit}
                    >
                      Save as Draft
                    </button>
                    <button
                      className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100"
                      onClick={() => handleApproval()}
                    >
                      Submit for Approval
                    </button>
                  </>
                )}
              </div>
              <button
                className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>

            <Modal
              title="Success"
              content={saveDraft ? "Blog saved as draft." : "Blog submitted for approval."}
              action="success"
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setSaveDraft(false);
              }}
            />


            {showCategoryModal && (
              <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
                <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-96">
                  <h3 className="tw-text-xl tw-font-bold tw-mb-4">Create New Category</h3>
                  <input
                    type="text"
                    placeholder="New Category"
                    className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-mb-4"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Sub-category (optional)"
                    className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-mb-4"
                    value={newSubCategory}
                    onChange={(e) => setNewSubCategory(e.target.value)}
                  />
                  <div className="tw-mb-4">
                    <h4 className="tw-font-semibold tw-mb-2">
                      Suggested Categories:
                    </h4>
                    <div className="tw-flex tw-flex-wrap tw-gap-2">
                      {suggestedCategories.map((category, index) => (
                        <button
                          key={index}
                          className="tw-border-2 tw-border-black tw-px-2 tw-py-1 tw-text-xs tw-bg-gray-200 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                          onClick={() => setNewCategory(category)}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-end tw-space-x-2">
                    <button
                      className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-bg-gray-200 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                      onClick={handleCreateCategory}
                    >
                      Create
                    </button>
                    <button
                      className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                      onClick={() => setShowCategoryModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>

      }
    </div >
  );
};

export default EditBlog;
