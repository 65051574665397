import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { deleteChallenge, getAllChallenges, getChallengeLeaderboard } from '../../../services/corporatewellness/Service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

function CorporateChallengesTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('All');
    const [challengesData, setChallengesData] = useState([]);
    const [challengeLeaderboardData, setChallengeLeaderboardData] = useState([]);
    const [leaderboardLoading, setLeaderboardLoading] = useState(false);
    const [leaderboardTeams, setLeaderboardTeams] = useState(false);
    const navigate = useNavigate();
    const [challengeId, setChallengeId] = useState(0);

    useEffect(() => {
        fetchChallenges();
      }, []);

    const fetchChallenges = async () => {
        try {
            const payload = {
                corporateId: 0,
              }
            const res = await getAllChallenges(payload);
    
            if (res.data.status === 200) {
                setChallengesData(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    const fetchChallengeLeaderboard = async (id, teams, downloadCsv) => {
        setLeaderboardLoading(true);
        try {
            const payload = {
                challengeId: id,
                teams,
                downloadCsv
            };

            const res = await getChallengeLeaderboard(payload);
    
            if (res.data.status === 200) {
                setChallengeLeaderboardData(res.data.data);
                setChallengeId(id);
                
                if (!teams) {
                    setLeaderboardTeams(false);
                } else {
                    setLeaderboardTeams(true);
                }
            } else {
                alert(res.data.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLeaderboardLoading(false);
        }
    }

    console.log(challengeLeaderboardData);
    

    const columns = [
        // {
        //     name: 'Leader Board',
        //     selector: row => row.leaderBoard,
        //     sortable: true,
        //     cell: row => (
        //         <a href="#" dangerouslySetInnerHTML={{ __html: row.leaderBoard }}></a>
        //     ),
        // },
        {
            name: 'Challenge Title',
            selector: row => row.challengeTitle,
            sortable: true,
        },
        {
            name: 'Challenge Description',
            selector: row => row.challengeDescription,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => moment(row.challengeStartsOn).format("DD MMM YYYY"),
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => moment(row.challengeEndsOn).format("DD MMM YYYY"),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.challengeState,
            sortable: true,
            cell: row => (
                <span style={{ color: row.challengeState === 'Started' ? '#01CD88' : row.challengeState === 'Ended' ? '#FF0000' : '#C8C8C8' }}>
                    {row.challengeState}
                </span>
            ),
        },
        {
            name: 'Leaderboard',
            cell: row => (
                row.challengeState === "Started" ? 
                <div>
                    <button className="btn btn-primary back-btn me-2" data-bs-toggle="modal" data-bs-target="#viewLeaderboardPopUps" onClick={() => fetchChallengeLeaderboard(row.challengeId, false, false)}>
                        View Leaderboard
                    </button>
                    <button className="btn btn-primary back-btn" data-bs-toggle="modal" data-bs-target="#viewLeaderboardPopUps" onClick={() => fetchChallengeLeaderboard(row.challengeId, true, false)}>
                        View Team Leaderboard
                    </button>
                </div> : <div>Leaderboard not generated yet.</div>
            ),
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    <button className="btn btn-Edit mx-2" onClick={() => navigate(`/edit-challenge/${row.challengeId}`)}>
                        <i className="bi bi-pencil"></i>
                    </button> 
                    |
                    <button className="btn btn-Cancel mx-2" data-bs-toggle="modal" data-bs-target="#deleteChallengeModal" onClick={() => openDeleteChallengePopup(row.challengeId)}>
                        <i className="bi bi-trash"></i>
                    </button>
                </div>
            ),
        },
    ];

    const data = [
        {
            id: 1,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 123',
            challengeDescription: 'Description of Walkathon Warriors 1',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 2,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 2',
            challengeDescription: 'Description of Walkathon Warriors 2',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 3,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 3',
            challengeDescription: 'Description of Walkathon Warriors 3',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 4,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 4',
            challengeDescription: 'Description of Walkathon Warriors 4',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 5,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 5',
            challengeDescription: 'Description of Walkathon Warriors 5',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 6,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 6',
            challengeDescription: 'Description of Walkathon Warriors 6',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 7,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 7',
            challengeDescription: 'Description of Walkathon Warriors 7',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 8,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 8',
            challengeDescription: 'Description of Walkathon Warriors 8',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 9,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 9',
            challengeDescription: 'Description of Walkathon Warriors 9',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
        {
            id: 10,
            leaderBoard: 'Click here to view leaderboard',
            challengeTitle: 'Walkathon Warriors 10',
            challengeDescription: 'Description of Walkathon Warriors 10',
            startDate: '07 Nov 2023',
            endDate: '12 Nov 2023',
        },
    ];

    const viewLeaderboards = [
        { 
            Rank: '1', 
            viewLeaderboardPics: 'https://appcdn.goqii.com/user/usercon/rdm63391/o_20579_1722945690.jpg', 
            viewLeaderboardName: 'Vandana Juneja', 
            Score: '5978' 
        },
        { 
            Rank: '2', 
            viewLeaderboardPics: 'https://goqii-app.s3.amazonaws.com/user/usercon/rdm10266/l_75080_1604669470.jpg', 
            viewLeaderboardName: 'Hui Shang Hsue', 
            Score: '4121' 
        },
        { 
            Rank: '3', 
            viewLeaderboardPics: 'https://goqii-app.s3.amazonaws.com/user/usercon/rdm29/l_90482_1583252374.jpg', 
            viewLeaderboardName: 'Yogita Agarwal', 
            Score: '2733' 
        },
      ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedTitle('All');
    };

    const filteredData = challengesData.filter(item => {        
        const matchesSearchQuery =
            (item.challengeTitle && item.challengeTitle.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.challengeDescription && item.challengeDescription.toLowerCase().includes(searchQuery.toLowerCase()));

        // const matchesTitle = selectedTitle === 'All' || item.challengeTitle === selectedTitle;

        // return matchesSearchQuery && matchesTitle;
        return matchesSearchQuery;
    });

    const openDeleteChallengePopup = (rowId) => {
        setChallengeId(rowId);
    };

    const handleDeleteChallenge = async () => {
        // setIsLoading(true);
        try {
            const payload = {
            challengeId: challengeId
            }
            const response = await deleteChallenge(payload);

            if (response.data.status === 200) {
                toast.success(response.data.message, {
                    position: 'top-right',
                    autoClose: 1000,
                    onClose: () => {
                      window.location.href = "/corporate-challenges";
                    }
                });   
            }
            // setIsLoading(false);
        } catch(e) {
            // setIsLoading(false);
            console.log(e);
        }
        
    }

    const downloadCSV = async (id, teams = false) => {
        // // setLeaderboardLoading(true);
        // try {
        //     const payload = {
        //         challengeId: id,
        //         teams,
        //         downloadCsv
        //     };

        //     const response = await getChallengeLeaderboard(payload);
    
        //     // Create a URL for the blob
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
            
        //     // Create a link element and trigger the download
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'leaderboard.csv'); // or get filename from Content-Disposition
        //     document.body.appendChild(link);
        //     link.click();
            
        //     // Clean up
        //     link.parentNode.removeChild(link);
        //     window.URL.revokeObjectURL(url);

        // } catch (error) {
        //     console.log(error);
        // } finally {
        //     // setLeaderboardLoading(false);
        // }
        // const url = `${process.env.REACT_APP_BASE_URL}challenge/fetch_leaderboard?challengeId=${id}&teams=${teams}&downloadCsv=true`;
        // window.location.href = url;
        try {
            const payload = {
                challengeId: id,
                teams: false,
                downloadCsv: true
            };

            const res = await getChallengeLeaderboard(payload);
        
            const blob = new Blob([res.data], { type: 'text/csv' });
            
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${teams ? 'teams_' : ''}leaderboard_${id}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        
            window.URL.revokeObjectURL(downloadUrl);
          } catch (error) {
            console.error('Error downloading CSV:', error);
          }
    }

    return (
        <>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Challenge or Leader Board"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src={require("../../../images/icon-search.png")} className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    {/* <div className='col-md-3 px-1'>
                        <select
                            value={selectedTitle}
                            onChange={e => setSelectedTitle(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Challenge Title--</option>
                            <option value="Walkathon Warriors 123">Walkathon Warriors 1</option>
                            <option value="Walkathon Warriors 2">Walkathon Warriors 2</option>
                            <option value="Walkathon Warriors 3">Walkathon Warriors 3</option>
                        </select>
                    </div> */}
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className="col-md-auto text-end pe-0"><Link className="btn btn-primary add-new-program-btn" to="/create-challenges">Create Challenge</Link></div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            <div class="modal fade" id="deleteChallengeModal" tabindex="-1" aria-labelledby="deleteChallengeModalLabel" data-bs-backdrop="static" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="deleteChallengeModalLabel">Are you sure, you want to delete this challenge?</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="col-md-12 my-2 text-center">
                            <button
                                type="button"
                                className="btn btn-primary program-save-btn me-2"
                                onClick={() => handleDeleteChallenge()}
                            >
                                Delete
                            </button>
                        </div>        
                    </div>
                </div>
            </div>

            <div className="modal fade" id="viewLeaderboardPopUps" tabindex="-1" aria-labelledby="viewLeaderboardPopUpsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="viewLeaderboardPopUpsLabel">Leaderboard - GOQii Elite Challenge</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                {(challengeLeaderboardData.length > 0) && <button onClick={() => downloadCSV(challengeId, leaderboardTeams)}>Download CSV</button>}
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    {leaderboardLoading ? 
                                    (
                                        <div>Loading...</div>
                                    ) 
                                    : 
                                    (
                                        <div className="table-responsive mt-2" style={{ maxHeight: '380px', overflowX: 'hidden' }}>
                                            {(challengeLeaderboardData.length > 0) ? (
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead style={{ position: 'sticky', top: 0 }}>
                                                        <tr>
                                                            <th scope="col" style={{ width: '10%' }}>Rank</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col" style={{ width: '15%' }}>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {/* {viewLeaderboards.map((viewLeaderboard, index) => (
                                                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                            <td className="bg-td-green"><b>{viewLeaderboard.Rank}</b></td>
                                                            <td className="bg-td-green"><a href=""><img src={viewLeaderboard.viewLeaderboardPics} class="leaderboard-img" alt=""/> {viewLeaderboard.viewLeaderboardName}</a></td>
                                                            <td className="bg-td-green"><b>{viewLeaderboard.Score}</b></td>
                                                        </tr>
                                                    ))} */}
                                                    {(challengeLeaderboardData.map((viewLeaderboard, index) => (
                                                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                            <td className="bg-td-green"><b>{viewLeaderboard.userRank}</b></td>
                                                            <td className="bg-td-green"><a href=""><img src={viewLeaderboard.image} class="leaderboard-img" alt=""/> {viewLeaderboard.firstName + " " + viewLeaderboard.lastName}</a></td>
                                                            <td className="bg-td-green"><b>{viewLeaderboard.userScore}</b></td>
                                                        </tr>
                                                    ))) 
                                                    }
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div>Leaderboard not generated yet.</div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CorporateChallengesTable;
