import React, { useState, useEffect } from 'react';
import HeaderDesc from './HeaderDesc';
import { FaSort, FaEye, FaThumbsUp, FaComment, FaCheck, FaTimes, FaEdit } from 'react-icons/fa';
import BlogTab from './BlogTab';
import { fetchApprovedArticles, publishUnpublishArticles, log_data, schedulePublishArticles, searchPost } from "../../../services/cms/Service";
import { useNavigate } from "react-router-dom";

const ContentManagement = () => {
  const [contentData, setContentData] = useState([
    { id: 1, contentType: 'Blog', title: 'Top 10 Fitness Tips for Beginners', status: 'Published', publishedDate: '2023-08-15', author: 'John Doe', approver: 'Jane Smith', totalViews: 5000, totalLikes: 320, totalComments: 45, action: 'Unpublish' },
    { id: 2, contentType: 'Workout Video', title: 'Full Body HIIT Workout', status: 'Pending', publishedDate: '-', author: 'Mike Johnson', approver: '-', totalViews: 0, totalLikes: 0, totalComments: 0, action: 'Publish' },
    { id: 3, contentType: 'Awareness Video', title: 'Understanding Nutrition Labels', status: 'Approved', publishedDate: '2023-08-20', author: 'Sarah Brown', approver: 'Tom Wilson', totalViews: 2500, totalLikes: 180, totalComments: 30, action: 'Publish' },
    { id: 4, contentType: 'Blog', title: 'The Importance of Mental Health in Fitness', status: 'Published', publishedDate: '2023-08-10', author: 'Emily Clark', approver: 'Jane Smith', totalViews: 3500, totalLikes: 250, totalComments: 38, action: 'Unpublish' },
    { id: 5, contentType: 'Workout Video', title: 'Yoga for Beginners', status: 'Published', publishedDate: '2023-08-05', author: 'Lisa Young', approver: 'Tom Wilson', totalViews: 7500, totalLikes: 500, totalComments: 60, action: 'Unpublish' },
  ]);

  const [commentData, setCommentData] = useState([
    { id: 1, contentId: 1, text: "Great tips! I've started implementing these in my routine.", status: 'pending', timestamp: '2023-08-22T10:30:00', author: 'FitnessEnthusiast' },
    { id: 2, contentId: 1, text: "Could you elaborate more on the importance of rest days?", status: 'pending', timestamp: '2023-08-23T09:15:00', author: 'NewbieRunner' },
    { id: 3, contentId: 3, text: "This video was very informative. Thanks for breaking down the nutrition label!", status: 'accepted', timestamp: '2023-08-23T14:45:00', author: 'HealthNut101' },
    { id: 4, contentId: 4, text: "I never realized how closely mental health and fitness were connected. Eye-opening article!", status: 'pending', timestamp: '2023-08-23T16:20:00', author: 'MindBodyBalance' },
    { id: 5, contentId: 5, text: "As a beginner, I found this yoga video extremely helpful and easy to follow.", status: 'accepted', timestamp: '2023-08-20T11:00:00', author: 'YogaNewbie' },
    { id: 6, contentId: 5, text: "Can you make a follow-up video for intermediate yoga practitioners?", status: 'pending', timestamp: '2023-08-22T13:30:00', author: 'FlexibleYogi' },
  ]);

  const [contentSort, setContentSort] = useState({ column: '', direction: 'asc' });
  const [commentSort, setCommentSort] = useState({ column: 'timestamp', direction: 'desc' });
  const [contentPage, setContentPage] = useState(1);
  const [commentPage, setCommentPage] = useState(1);
  const [contentSearch, setContentSearch] = useState('');
  const [commentSearch, setCommentSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [actionId, setActionId] = useState(null);
  const [blogsPerPage] = useState(5);

  const itemsPerPage = 5;
  const roleId = localStorage.getItem('roleId')
  const navigate = useNavigate();

  console.log(contentData, "contentData")

  const [searchPage, setSearchPage] = useState(1);
  const [searchTotalPages, setSearchTotalPages] = useState(1);
  const [filteredBlogs, setFilteredBlogs] = useState(contentData);

  const handlePagination = (page) => {
    if (contentSearch) {
      setSearchPage(page);
    } else {
      setCurrentPage(page);
    }
  };


  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => {
    // Sort comments by timestamp (latest first) when component mounts
    const sortedComments = [...commentData].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setCommentData(sortedComments);
    fetch_Approved_Articles()
  }, []);

  useEffect(() => {
    fetch_Approved_Articles()
  }, [currentPage]);


  async function fetch_Approved_Articles() {
    try {
      let resp = await fetchApprovedArticles({ pageNo: currentPage });
      console.log(resp, "response")
      const dataArray = JSON.parse(resp.data?.data?.articles);
      setContentData(dataArray)
      setFilteredBlogs(dataArray)
      setTotalPages(Math.floor(resp.data?.data?.totalPages))

    } catch (e) {

    }

  }


  async function publish_Unpublish_Articles(id, action) {
    try {
      let resp = await publishUnpublishArticles({ pid: id, action: action });
      log_data({ pid: id, action: action })
      console.log(resp, "response")

    } catch (e) {

    }

  }


  const handleSort = (table, column) => {
    if (table === 'content') {
      setContentSort(prev => ({
        column,
        direction: prev.column === column && prev.direction === 'asc' ? 'desc' : 'asc'
      }));
    } else {
      setCommentSort(prev => ({
        column,
        direction: prev.column === column && prev.direction === 'asc' ? 'desc' : 'asc'
      }));
    }
  };

  const handleAction = (id, action) => {
    console.log(id, "id")
    console.log(action, "action")
    setActionId(id);
    if (action == "publish") {
      setShowModal(true);
      //   setContentData(contentData.map(content => content.ID === id ? { ...content, post_status: 'published' } : content));
    } else if (action == "unpublish") {
      setContentData(contentData.map(content => content.ID === id ? { ...content, post_status: 'Approved' } : content));
      publish_Unpublish_Articles(id, 'unpublish');
    }
  };

  const publishImmediately = (id) => {
    publish_Unpublish_Articles(id, 'publish');
    setContentData(contentData.map(content => content.ID === id ? { ...content, post_status: 'published' } : content));
    setShowModal(false);
  };

  const publishLater = (id, date) => {
    // Handle scheduling logic
    console.log(`Publishing ${id} at ${date}`);
    // Send `id` and `date` to backend
    schedulePublishArticles({ pid: id, date: date })
    setContentData(contentData.map(content => content.ID === id ? { ...content, post_status: 'published' } : content));
    setShowModal(false);
  };


  const handleCommentAction = (id, action) => {
    setCommentData(commentData.map(comment =>
      comment.id === id ? { ...comment, status: action === 'accept' ? 'accepted' : 'rejected' } : comment
    ));
  };

  // const filteredContent = contentData.filter(item =>
  //   item.title.toLowerCase().includes(contentSearch.toLowerCase()) ||
  //   item.author.toLowerCase().includes(contentSearch.toLowerCase())
  // );

  const filteredComments = commentData.filter(comment =>
    comment.text.toLowerCase().includes(commentSearch.toLowerCase()) ||
    comment.author.toLowerCase().includes(commentSearch.toLowerCase())
  );

  // const sortedContent = [...filteredContent].sort((a, b) => {
  //   if (a[contentSort.column] < b[contentSort.column]) return contentSort.direction === 'asc' ? -1 : 1;
  //   if (a[contentSort.column] > b[contentSort.column]) return contentSort.direction === 'asc' ? 1 : -1;
  //   return 0;
  // });

  const sortedComments = [...filteredComments].sort((a, b) => {
    if (a[commentSort.column] < b[commentSort.column]) return commentSort.direction === 'asc' ? -1 : 1;
    if (a[commentSort.column] > b[commentSort.column]) return commentSort.direction === 'asc' ? 1 : -1;
    return 0;
  });

  // const paginatedContent = sortedContent.slice((contentPage - 1) * itemsPerPage, contentPage * itemsPerPage);
  const paginatedComments = sortedComments.slice((commentPage - 1) * itemsPerPage, commentPage * itemsPerPage);

  const handleSearch = async (page = 1) => {
    try {
      let response = await searchPost({ searchTerm: contentSearch, action: 'published', pageNo: page });
      console.log(response.data.data, "search response")
      if (response.data.code == 200) {
        const dataArray = JSON.parse(response.data?.data?.articles);
        setFilteredBlogs(dataArray);
        setSearchTotalPages(Math.floor(response.data?.data?.totalPages))
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (contentSearch) {
      handleSearch(searchPage);
    } else {
      fetch_Approved_Articles(currentPage);
    }
  }, [currentPage, searchPage, contentSearch]);

  return (
    <div className="tw-mx-auto tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-lg tw-mb-16">
      <BlogTab selectedIndex={roleId == 25 ? 2 : 3} />

      <HeaderDesc
        image={'https://www.popwebdesign.net/images/web-development/graphics/content-management-systems-header.png'}
        desc={'Oversee publishing and unpublishing of content. Manage visibility and ensure content stays current and aligned with strategy, adapting to changing priorities or trends.'}
      />

      {/* Content Table */}
      <div className="tw-mb-8">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Content Management</h2>
        <input
          type="text"
          placeholder="Search content..."
          value={contentSearch}
          onChange={(e) => {
            setContentSearch(e.target.value);
            setSearchPage(1); // Reset search pagination on new search
          }}
          className="tw-w-full tw-p-2 tw-mb-4 tw-border tw-rounded"
        />
        {/* <button
          className="tw-ml-2 tw-px-4 tw-py-1 tw-bg-black tw-text-white tw-rounded-lg tw-h-12 tw-mt-3"
          onClick={() => handleSearch(1)}
        >
          Search
        </button> */}
        <div className="tw-overflow-x-auto">
          <table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
            <thead className="tw-text-xs tw-text-white tw-uppercase tw-bg-blue-600">
              <tr>
                {roleId != 27 ?
                  <>
                    {['Content Type', 'Title', 'Status', 'Published Date', 'Author', 'Approver'].map((header) => (
                      <th key={header} onClick={() => handleSort('content', header.toLowerCase())} className="tw-cursor-pointer tw-px-6 tw-py-3">
                        {header} <FaSort className="tw-inline" />
                      </th>
                    ))}</> : <>
                    {['Content Type', 'Title', 'Status', 'Published Date', 'Author', 'Approver', 'Actions'].map((header) => (
                      <th key={header} onClick={() => handleSort('content', header.toLowerCase())} className="tw-cursor-pointer tw-px-6 tw-py-3">
                        {header} <FaSort className="tw-inline" />
                      </th>
                    ))}
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {filteredBlogs
                .map((row) => (
                  <tr key={row.id} className="tw-bg-white tw-border-b hover:tw-bg-gray-50">
                    <td className="tw-px-6 tw-py-4">{row.post_type}</td>
                    <td className="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900">{row.post_title}</td>
                    <td className="tw-px-6 tw-py-4">
                      <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-semibold
                      ${row.post_status === 'Published' ? 'tw-bg-green-100 tw-text-green-800' :
                          row.post_status === 'Approved' ? 'tw-bg-yellow-100 tw-text-yellow-800' :
                            'tw-bg-blue-100 tw-text-blue-800'}`}>
                        {row.post_status}
                      </span>
                    </td>
                    <td className="tw-px-6 tw-py-4">
                      {row.post_status ? (() => {
                        let postDate = new Date(row.post_date);
                        postDate.setHours(postDate.getHours() + 5);
                        postDate.setMinutes(postDate.getMinutes() + 30);
                        return postDate.toLocaleString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          timeZone: 'Asia/Kolkata'
                        });
                      })() : ""}
                    </td>
                    <td className="tw-px-6 tw-py-4">{row.post_author}</td>
                    <td className="tw-px-6 tw-py-4">{row.post_author}</td>
                    {/* <td className="tw-px-6 tw-py-4">
                    <div className="tw-flex tw-items-center tw-space-x-2">
                      <span title="Views" className="tw-flex tw-items-center"><FaEye className="tw-mr-1" /> {row.totalViews}</span>
                      <span title="Likes" className="tw-flex tw-items-center"><FaThumbsUp className="tw-mr-1" /> {row.totalLikes}</span>
                      <span title="Comments" className="tw-flex tw-items-center"><FaComment className="tw-mr-1" /> {row.totalComments}</span>
                    </div>
                  </td> */}

                    <td className="tw-px-6 tw-py-4 tw-flex tw-flex-row tw-items-center tw-gap-2">

                      <button className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300" onClick={() => navigate(`/content-management/preview-post/${row.ID}?type='publish'`)}>
                        <FaEye />
                      </button>
                      {
                        row.post_type === "library_video" || row.post_type === "upload_video" ? (
                          <button
                            className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
                            onClick={() => navigate(`/content-management/add-video-library?id=${row.ID}&type=publish`)}
                          >
                            <FaEdit />
                          </button>
                        ) : row.post_type === "yt_video" ? (
                          <button
                            className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
                            onClick={() => navigate(`/content-management/edit-yt-video/${row.ID}`)}
                          >
                            <FaEdit />
                          </button>
                        ) : row.post_type === "post" ? (
                          <button
                            className="tw-border tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
                            onClick={() => navigate(`/content-management/edit-post/${row.ID}?type=publish`)}
                          >
                            <FaEdit />
                          </button>
                        ) : null
                      }
                      {roleId == 27 ?
                        <button
                          className={`tw-px-3 tw-py-1 tw-rounded-md tw-text-white tw-font-semibold
                        ${row.post_status === 'Approved' ? 'tw-bg-green-500 hover:tw-bg-green-600' : 'tw-bg-yellow-500 hover:tw-bg-yellow-600'}`}
                          onClick={() => handleAction(row.ID, row.post_status === 'Approved' ? 'publish' : 'unpublish')}
                        >
                          {row.post_status === 'Approved' ? "Publish" : "Unpublish"}
                        </button> : ""}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {
            showModal && (
              <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
                <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-w-96 tw-shadow-lg">
                  <h3 className="tw-text-2xl tw-font-bold tw-mb-6 tw-text-center">
                    Select Publish Option
                  </h3>

                  <div className="tw-mb-4 tw-space-y-4">
                    <button
                      className="tw-w-full tw-py-3 tw-bg-blue-500 tw-text-white tw-rounded-lg tw-font-semibold hover:tw-bg-blue-600 tw-transition tw-duration-300 tw-shadow-md"
                      onClick={() => publishImmediately(actionId)}
                    >
                      Publish Now
                    </button>

                    <div className="tw-flex tw-flex-col tw-space-y-3">
                      <label className="tw-font-semibold">Schedule Publish:</label>
                      <input
                        type="datetime-local"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-lg focus:tw-border-blue-500 focus:tw-outline-none"
                      />
                      <button
                        className="tw-w-full tw-py-3 tw-bg-gray-200 tw-border-2 tw-border-black tw-rounded-lg tw-font-semibold hover:tw-bg-black hover:tw-text-white tw-transition tw-duration-300 tw-shadow-md"
                        onClick={() => publishLater(actionId, selectedDate)}
                      >
                        Schedule
                      </button>
                    </div>
                  </div>

                  <div className="tw-flex tw-justify-end tw-space-x-4 tw-mt-6">
                    {/* <button
                      className="tw-px-6 tw-py-2 tw-bg-green-500 tw-text-white tw-rounded-lg tw-font-semibold hover:tw-bg-green-600 tw-transition tw-duration-300 tw-shadow-md"
                      onClick={() => setShowModal(false)}
                    >
                      Submit
                    </button> */}
                    <button
                      className="tw-px-6 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded-lg tw-font-semibold hover:tw-bg-red-600 tw-transition tw-duration-300 tw-shadow-md"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )
          }

        </div>
        <div className="tw-mt-4 tw-flex tw-justify-center">
          {/* {Array.from({ length: totalPages }, (_, i) => i + 1).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`tw-mx-1 tw-px-3 tw-py-1 tw-rounded ${currentPage === index + 1 ? 'tw-bg-blue-500 tw-text-white' : 'tw-bg-gray-200'}`}
            >
              {index + 1}
            </button>
          ))} */}
          {Array.from(
            { length: contentSearch ? searchTotalPages : totalPages },
            (_, i) => i + 1
          ).map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => handlePagination(pageNum)}
              className={`tw-mx-1 tw-px-3 tw-py-1 tw-rounded
              ${contentSearch ? searchPage : currentPage === pageNum ? 'tw-bg-blue-500 tw-text-white' : 'tw-bg-gray-200'}
            `}
            >
              {pageNum}
            </button>
          ))}
        </div>
      </div>

      {/* Comments Table */}
      <div>
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Comment Management</h2>
        <input
          type="text"
          placeholder="Search comments..."
          value={commentSearch}
          onChange={(e) => setCommentSearch(e.target.value)}
          className="tw-w-full tw-p-2 tw-mb-4 tw-border tw-rounded"
        />
        <div className="tw-overflow-x-auto">
          <table className="tw-w-full tw-text-sm tw-text-left tw-text-gray-500">
            <thead className="tw-text-xs tw-text-white tw-uppercase tw-bg-blue-600">
              <tr>
                {['Content', 'Comment', 'Author', 'Timestamp', 'Status', 'Actions'].map((header) => (
                  <th key={header} onClick={() => handleSort('comment', header.toLowerCase())} className="tw-cursor-pointer tw-px-6 tw-py-3">
                    {header} <FaSort className="tw-inline" />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedComments.map((comment) => (
                <tr key={comment.id} className="tw-bg-white tw-border-b hover:tw-bg-gray-50">
                  <td className="tw-px-6 tw-py-4">{contentData.find(item => item.id === comment.contentId)?.title}</td>
                  <td className="tw-px-6 tw-py-4">{comment.text}</td>
                  <td className="tw-px-6 tw-py-4">{comment.author}</td>
                  <td className="tw-px-6 tw-py-4">{new Date(comment.timestamp).toLocaleString()}</td>
                  <td className="tw-px-6 tw-py-4">
                    <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-semibold
                      ${comment.status === 'accepted' ? 'tw-bg-green-100 tw-text-green-800' :
                        comment.status === 'rejected' ? 'tw-bg-red-100 tw-text-red-800' :
                          'tw-bg-yellow-100 tw-text-yellow-800'}`}>
                      {comment.status}
                    </span>
                  </td>
                  <td className="tw-px-6 tw-py-4">
                    {comment.status === 'pending' && (
                      <div className="tw-flex tw-items-center">
                        <button
                          className="tw-mr-2 tw-bg-green-500 tw-text-white tw-px-3 tw-py-1 tw-rounded-md hover:tw-bg-green-600 tw-flex tw-items-center"
                          onClick={() => handleCommentAction(comment.id, 'accept')}
                        >
                          <FaCheck className="tw-inline tw-mr-1" /> Accept
                        </button>
                        <button
                          className="tw-bg-red-500 tw-text-white tw-px-3 tw-py-1 tw-rounded-md hover:tw-bg-red-600 tw-flex tw-items-center"
                          onClick={() => handleCommentAction(comment.id, 'reject')}
                        >
                          <FaTimes className="tw-inline tw-mr-1" /> Reject
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="tw-mt-4 tw-flex tw-justify-center">
          {[...Array(Math.ceil(sortedComments.length / itemsPerPage))].map((_, index) => (
            <button
              key={index}
              onClick={() => setCommentPage(index + 1)}
              className={`tw-mx-1 tw-px-3 tw-py-1 tw-rounded ${commentPage === index + 1 ? 'tw-bg-blue-500 tw-text-white' : 'tw-bg-gray-200'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

    </div>


  );
};

export default ContentManagement;