import React, { useState } from 'react';
import './PWADiabetic.css';


function PWADiabetic() {


  return (
    <>
      <section className='PWADiabetic'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">GOQii Health Engage's Health Manage App</h1>
            </div>
           
          </div>

          <div className='PWA-Diabetic mb-3'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='windows'>
                        <div className="iphone">
                            <div className="border">
                                <div className='quate-bg'>
                                    <iframe data-v-25bcc656="" sandbox="allow-scripts allow-same-origin allow-popups allow-pointer-lock allow-orientation-lock allow-modals allow-forms" allow="geolocation 'self'" name="simulator" src="https://healthengage.goqii.com/join-diabetic-wellness-plan?uid=ZGJIYzlGb2dKdFZOa2pSSGRQcUJSQT09" className="iframe-bg"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
              
                </div>
            </div>
          </div>


        </div>
      </section>


    </>
  );
}

export default PWADiabetic;
