import React, { useEffect, useState } from "react";
import ChallengeTaskHra from "./ChallengeTaskHra";
import { Link } from "react-router-dom";

const allVideoTypes = [
  {
    label: "Any Video",
    value: "any",
  },
  {
    label: "Specific Video",
    value: "specific",
  },
  {
    label: "Live Video",
    value: "live",
  },
];

const ChallengeTaskList = ({ allTaskLists,handleTaskInputChange,singleTaskData,recordNum,handleAddHra,handleRemoveHra,handleHraInputChange,handleCsvFileChange,setTaskListsData,action,handleFileChange }) => {
  const [dynamicExplainText, setDynamicExplainText] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [videoCsvFileUrl, setVideoCsvFileUrl] = useState("");

  useEffect(() => {
    const points = singleTaskData.points ? singleTaskData.points : "points";
    const totalPoints = singleTaskData.totalPoints;
    // const target = singleTaskData.target ? singleTaskData.target : "target";
    const target = singleTaskData.target ? singleTaskData.target : 
    (singleTaskData.taskKeyword === "yogaChallenge" || singleTaskData.taskKeyword === "meditation") ? "Minutes" :
    (singleTaskData.taskKeyword === "foodLog" || singleTaskData.taskKeyword === "waterLog" || singleTaskData.taskKeyword === "activityLog") ? "Log Done" :
    (singleTaskData.taskKeyword === "trackerSleep") ? "Sleep Target" :
    (singleTaskData.taskKeyword === "trackerSteps") ? "Steps Target" :
    (singleTaskData.taskKeyword === "HRA") ? "HRA Taken" :
    (singleTaskData.taskKeyword === "dailyBonus") ? "Points Earned" :
    (singleTaskData.taskKeyword === "Quiz") ? "Quiz Played"
      : "Target";
    // const displayTarget = singleTaskData.displayTarget;

    if (singleTaskData.taskKeyword === "trackerSteps") {
      setDynamicExplainText(
        `Every "${target}" steps walked, will get "${points}" points.`
      );
    } else if (
      singleTaskData.taskKeyword === "foodLog" ||
      singleTaskData.taskKeyword === "waterLog" ||
      singleTaskData.taskKeyword === "activityLog"
    ) {
      setDynamicExplainText(
        `Every "${target}" log, will get "${points}" points.`
      );
    } else if (singleTaskData.taskKeyword === "Quiz") {
      setDynamicExplainText(
        `Every "${target}" correct question, will get "${points}" points.`
      );
    } else if (singleTaskData.taskKeyword === "trackerSleep") {
      setDynamicExplainText(
        `Hit your sleep goal, earn "${points}" points`
      );
    } else if (singleTaskData.taskKeyword === "HRA") {
      setDynamicExplainText(
        `Complete your HRA, get "${points}" points.`
      );
    } else if (singleTaskData.taskKeyword === "bonusPoints") {
      setDynamicExplainText(
        `Complete all daily tasks, unlock bonus points.`
      );
    } else if (singleTaskData.taskKeyword === "yoga_challenge") {
      setDynamicExplainText(
        `Complete a session with your Caoch, earn "${points}" points.`
      );
    } else {
      setDynamicExplainText("");
    }

    if (singleTaskData.videoType) {
      setSelectedOption(singleTaskData.videoType);

      if (singleTaskData.videoCsvFileUrl !== "") {
        setVideoCsvFileUrl(singleTaskData.videoCsvFileUrl); 
      }
    }
  }, [singleTaskData]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    setTaskListsData((prevTasks) => {
      return prevTasks.map((task, i) => {
        return ((task.challengeActivityType === singleTaskData.challengeActivityType) && task.taskKeyword === "yogaChallenge") ? 
          value === "live" ? {...task, "videoType": value, "live": true } : {...task, "videoType": value, "live": false } : task;
      });
    });
  };

  const downloadSampleCsv = () => {
    const csvUrl = "https://storage.googleapis.com/ksabupatest/2024/10/17/5elh5/ghw18448xs.csv";
    const link = document.createElement("a");
    link.href = csvUrl;
    link.target = "_blank";
    link.download = "video_upload_sample.csv"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="tw-max-w-lg tw-bg-gray-100 tw-shadow-md tw-rounded-lg tw-p-6 tw-mb-4">
      <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-mb-4">
        <div className="tw-col-span-3">
          <label
            htmlFor={`text-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            <strong>{dynamicExplainText}</strong>
          </label>
        </div>
        {/* Task Text Select */}
        <div className="tw-col-span-3">
          <label
            htmlFor={`taskText-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            Task Text
          </label>
          <select
            id={`taskKeyword-${recordNum}`}
            name="taskKeyword"
            value={singleTaskData.taskKeyword || ""}
            onChange={handleTaskInputChange}
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          >
            <option value="">Select Task</option>
            {allTaskLists.map((task, index) => (
              <option key={index} value={task.taskKeyword}>
                {task.taskText}
              </option>
            ))}
          </select>
        </div>
        {/* Points Input */}
        <div>
          <label
            htmlFor={`points-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            Points
          </label>
          <input
            type="text"
            id={`points-${recordNum}`}
            name="points"
            value={singleTaskData.points || ""}
            onChange={handleTaskInputChange}
            disabled={
              singleTaskData.taskKeyword === "Quiz"
                ? true
                : false
            }
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div>

        {/* Total Points Input */}
        <div>
          <label
            htmlFor={`totalPoints-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            {/* Total Points */}
            Max PTS in a day
          </label>
          <input
            type="text"
            id={`totalPoints-${recordNum}`}
            name="totalPoints"
            value={
              singleTaskData.taskKeyword === "syncTracker"
                ? "0"
                : singleTaskData.taskKeyword === "activityLog" ||
                  singleTaskData.taskKeyword === "trackerSleep" ||
                  singleTaskData.taskKeyword === "dailyBonus" ||
                  singleTaskData.taskKeyword === "yogaChallenge" ||
                  singleTaskData.taskKeyword === "meditation"
                ? singleTaskData.points
                : singleTaskData.totalPoints || ""
            }
            onChange={handleTaskInputChange}
            disabled={
              singleTaskData.taskKeyword === "syncTracker" ||
              singleTaskData.taskKeyword === "activityLog" ||
              singleTaskData.taskKeyword === "trackerSleep" ||
              singleTaskData.taskKeyword === "dailyBonus" ||
              singleTaskData.taskKeyword === "yogaChallenge" ||
              singleTaskData.taskKeyword === "meditation"
                ? true
                : false
            }
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div>

        {/* Target Input */}
        <div>
          <label
            htmlFor={`target-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            {
            (singleTaskData.taskKeyword === "yogaChallenge" || singleTaskData.taskKeyword === "meditation") ? "Minutes" :
            (singleTaskData.taskKeyword === "foodLog" || singleTaskData.taskKeyword === "waterLog" || singleTaskData.taskKeyword === "activityLog") ? "Log Done" :
            (singleTaskData.taskKeyword === "trackerSleep") ? "Sleep Target" :
            (singleTaskData.taskKeyword === "trackerSteps") ? "Steps Target" :
            (singleTaskData.taskKeyword === "HRA") ? "HRA Taken" :
            (singleTaskData.taskKeyword === "dailyBonus") ? "Points Earned" :
            (singleTaskData.taskKeyword === "Quiz") ? "Quiz Played"
              : "Target"}
          </label>
          <input
            type="text"
            id={`target-${recordNum}`}
            name="target"
            value={singleTaskData.target || ""}
            onChange={handleTaskInputChange}
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div>

        {/* Display Target Input */}
        {/* <div>
          <label
            htmlFor={`displayTarget-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            Display Target
          </label>
          <input
            type="text"
            id={`displayTarget-${recordNum}`}
            name="displayTarget"
            value={
              singleTaskData.taskKeyword === "activityLog" ||
              singleTaskData.taskKeyword === "trackerSleep" ||
              singleTaskData.taskKeyword === "syncTracker" ||
              singleTaskData.taskKeyword === "dailyBonus" ||
              singleTaskData.taskKeyword === "yogaChallenge" ||
              singleTaskData.taskKeyword === "Quiz" ||
              singleTaskData.taskKeyword === "meditation"
                ? "0"
                : singleTaskData.displayTarget || ""
            }
            onChange={handleTaskInputChange}
            disabled={
              singleTaskData.taskKeyword === "activityLog" ||
              singleTaskData.taskKeyword === "trackerSleep" ||
              singleTaskData.taskKeyword === "syncTracker" ||
              singleTaskData.taskKeyword === "dailyBonus" ||
              singleTaskData.taskKeyword === "yogaChallenge" ||
              singleTaskData.taskKeyword === "Quiz" ||
              singleTaskData.taskKeyword === "meditation"
                ? true
                : false
            }
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div> */}

        {/* Sub Title Input */}
        <div className="tw-col-span-3">
          <label
            htmlFor={`displayTarget-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            Sub Title
          </label>
          <input
            type="text"
            id={`subTitle-${recordNum}`}
            name="subTitle"
            value={singleTaskData.subTitle || ""}
            onChange={handleTaskInputChange}
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          />
        </div>
      </div>

      {singleTaskData.taskKeyword.toLowerCase().includes("hra") && 
      <div className="row mb-4">
        <div className="col">
          <div className="row">
            <label className="form-label sub-title">HRA Image</label>
            <div className="col pe-1">
              <input
                type="file"
                className="form-control input-field-ui"
                id=""
                placeholder="Enter Challenge Title"
                onChange={handleFileChange}
              />
            </div>
            <div className="form-text">Image dimensions should be close to 800x200</div>
          </div>
        </div>
      </div>
      }

      {singleTaskData.taskKeyword.toLowerCase().includes("hra") && (
        <div className="row mb-4">
          <div className="col-md-7">
            <label className="form-label sub-title mb-0">Take HRA</label> <br />
            <label className="form-label">
              Total number of HRA throughout the challenge.
            </label>
          </div>

          {singleTaskData.hraData.map((val, i) => (
            <div key={i} className="d-flex align-items-center mb-2">
              <div className="col-md-auto tw-mr-4">
                <label className="form-label sub-title mb-0">{i + 1}</label>
              </div>
              <ChallengeTaskHra
                recordNum={i}
                singleHraData={val}
                handleHraInputChange={(e) => handleHraInputChange(e, i)}
              />
              {singleTaskData.hraData.length > 1 && i > 0 && (
                <div className="col-md-1">
                  <button
                    className="btn text-danger"
                    onClick={() => handleRemoveHra(i)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              )}
            </div>
          ))}

          {singleTaskData.hraData.length < 3 && (
            <div className="col-md-7">
              <button
                type="button"
                className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                onClick={handleAddHra}
              >
                <i className="bx bxs-plus-circle"></i> Add HRA
              </button>
            </div>
          )}
        </div>
      )}

      {singleTaskData.taskKeyword.toLowerCase().includes("yogachallenge") && (
        // <div className="col mb-4">
        //   <div className="row">
        //     <label>
        //       <input
        //         className=""
        //         type="radio"
        //         name="any"
        //         value="any"
        //         checked={selectedOption === "any"}
        //         onChange={handleChange}
        //       />
        //       Any Video
        //     </label>
        //   </div>
        //   <div className="row">
        //     <label>
        //       <input
        //         className=""
        //         type="radio"
        //         name="specific"
        //         value="specific"
        //         checked={selectedOption === "specific"}
        //         onChange={handleChange}
        //       />
        //       Specific Video
        //     </label>
        //   </div>
        //   {(selectedOption === "specific") && (action === "edit") && (
        //     <div className="row">
        //       <label className="form-label sub-title">Upload a file:</label>
        //       <div className="col pe-1">
        //         <input
        //           type="file"
        //           className="form-control input-field-ui"
        //           id=""
        //           name="videoCsv"
        //           onChange={(e) =>
        //             handleCsvFileChange(e, "videoCsv")
        //           }
        //           accept=".csv"
        //         />
        //       </div>
        //       {(videoCsvFileUrl !== "" && videoCsvFileUrl !== undefined) && <Link to={singleTaskData.videoCsvFileUrl}>Uploaded File.</Link>}
        //     </div>
        //   )}
        //   <label>
        //     <input
        //       type="radio"
        //       name="live"
        //       value="live"
        //       checked={selectedOption === "live"}
        //       onChange={handleChange}
        //     />
        //     Live Video
        //   </label>
        // </div>
        <div className="tw-col-span-2">
          <label
            htmlFor={`videoType-${recordNum}`}
            className="tw-block tw-text-gray-700 tw-mb-1"
          >
            Video Type
          </label>
          <select
            id={`videoType-${recordNum}`}
            name="videoType"
            value={singleTaskData.videoType || ""}
            onChange={handleChange}
            className="tw-block tw-w-full tw-py-2 tw-px-3 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500"
          >
            <option value="">Select Type</option>
            {allVideoTypes.map((video, index) => (
              <option key={index} value={video.value}>
                {video.label}
              </option>
            ))}
          </select>
          {/* {(selectedOption === "specific") && (action === "edit") && ( */}
          {(selectedOption === "specific") && (
            <div className="row">
              <label className="form-label sub-title">Upload a file:</label>
              <div className="col pe-1">
                <input
                  type="file"
                  className="form-control input-field-ui"
                  id=""
                  name="videoCsv"
                  onChange={(e) =>
                    handleCsvFileChange(e, "videoCsv")
                  }
                  accept=".csv"
                />
              </div>
              {/* {(videoCsvFileUrl !== "" && videoCsvFileUrl !== undefined) && <Link to={singleTaskData.videoCsvFileUrl}>Uploaded File.</Link>} */}
              <div className="row">
                <div className="col">
                  <Link to="#" onClick={downloadSampleCsv}>Download Sample File.</Link>
                </div>
              {singleTaskData.videoData && <div className="col tw-text-end"><Link to={""} data-bs-toggle="modal" data-bs-target="#videoDataModal">View Uploaded Data.</Link></div>}
              </div>
            </div>
          )}
        </div>
      )}

      {/* {singleTaskData.taskKeyword.toLowerCase().includes("quiz") && (action === "edit") && ( */}
      {singleTaskData.taskKeyword.toLowerCase().includes("quiz") && (
      <div className="tw-col-span-2">
          <div className="row">
            <label className="form-label sub-title">Upload a file:</label>
            <div className="col pe-1">
              <input
                type="file"
                className="form-control input-field-ui"
                id=""
                name="quizCsv"
                onChange={(e) =>
                  handleCsvFileChange(e, "quizCsv")
                }
                accept=".csv"
              />
            </div>
            <div className="row">
              <div className="col">
                <Link to="#" onClick={downloadSampleCsv}>Download Sample File.</Link>
              </div>
              {singleTaskData.quizData && <div className="col tw-text-end"><Link to={""} data-bs-toggle="modal" data-bs-target="#quizDataModal">View Uploaded Data.</Link></div>}
            </div>
          </div>
        </div>
      )}

      {selectedOption === "specific" && singleTaskData.videoData && 
      <div className="modal fade" id="videoDataModal" tabIndex="-1" aria-labelledby="videoDataModalLabel" data-bs-backdrop="static" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                  <div className="modal-header">
                      <h1 className="modal-title fs-5" id="videoDataModalLabel">Uploaded Video Logs</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <table>
                      <thead>
                        <tr>
                          <th>Log Date</th>
                          <th>Video ID</th>
                        </tr>
                      </thead>
                      <tbody>
                      {singleTaskData.videoData.map((val, i) => {
                        return (
                          <tr key={i}>
                            <td>{val.logDate}</td>
                            <td>{val.videoId}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
              </div>
          </div>
      </div>
      }

      {singleTaskData.taskKeyword.toLowerCase().includes("quiz") && singleTaskData.quizData && 
      <div className="modal fade" id="quizDataModal" tabIndex="-1" aria-labelledby="quizDataModalLabel" data-bs-backdrop="static" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                  <div className="modal-header">
                      <h1 className="modal-title fs-5" id="quizDataModalLabel">Uploaded Quiz Logs</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <table>
                      <thead>
                        <tr>
                          <th>Log Date</th>
                          <th>Quiz ID</th>
                        </tr>
                      </thead>
                      <tbody>
                      {singleTaskData.quizData.map((val, i) => {
                        return (
                          <tr key={i}>
                            <td>{val.logDate}</td>
                            <td>{val.quizId}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
              </div>
          </div>
      </div>
      }
    </div>
  );
};

export default ChallengeTaskList;
