import React, { Fragment, useEffect, useState } from "react";
import "./CorporateDetail.css";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CorporatesManageDetails from "./component/ManageCorp/CorporatesManageDetails";
import DemographicsGender from "./component/ManageCorp/DemographicsGender";
import DemographicsAgeGroup from "./component/ManageCorp/DemographicsAgeGroup";
import HealthGoalsChart from "./component/ManageCorp/HealthGoalsChart";
import StepsChart from "./component/ManageCorp/StepsChart";
import WaterChart from "./component/ManageCorp/WaterChart";
import SleepChart from "./component/ManageCorp/SleepChart";
import CorporateActiveEmployees from "./component/CorpWellDashboard/CorporateActiveEmployees";
import { getCorporateDashboardMetrics } from "../../services/corporatewellness/Service";

function CorporateDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [demographicsData, setDemographicsData] = useState([]);
  const [dailytargetGoalsData, setDailytargetGoalsData] = useState([])

  useEffect(() => {
    fetchMetrics();
    fetchDailyTargetGoalsMetrics();
  }, []);

  const fetchMetrics = async () => {
    try {
      const payload = {
        action: "demographics",
        corporateId: id,
      };
      const res = await getCorporateDashboardMetrics(payload);

      if (res.data.status === 200) {
        setDemographicsData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDailyTargetGoalsMetrics = async () => {
    try {
      const payload = {
        action: "dailyTargetGoals",
        corporateId: id,
      };
      const res = await getCorporateDashboardMetrics(payload);

      if (res.data.status === 200) {
        setDailytargetGoalsData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackButton = () => {
    navigate("/corporate-dashboard");
  };

  const corporateManageCorporate = [
    {
      corpImgManageCorporate:
        "https://appcdn.goqii.com/storeimg/79550_1727348575.jpg",
      corpTItleManageCorporate: "Indian Oil Corporation",
    },
  ];

  return (
    <>
      <section className="CorporateDetail ">
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div
              className="col-md"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-primary back-btn mb-0 me-3"
                onClick={handleBackButton}
              >
                <i className="bi bi-chevron-left me-1"></i> Back
              </button>
              <h1 className="main-title" style={{ margin: "0" }}>
                Manage Corporates Details
              </h1>
            </div>
            <div className="col-md-auto text-end">
              <Link
                className="btn btn-primary add-new-program-btn me-2"
                to={`/create-groups/${id}`}
              >
                Create Groups
              </Link>{" "}
              <Link
                className="btn btn-primary add-new-program-btn"
                to="/create-challenges"
              >
                Create Challenge
              </Link>
            </div>
          </div>

          <div className="corporate-detail p-3 mb-3">
            <div className="row align-items-center">
              {/* {corporateManageCorporate.map((statEmp, index) => (
                <Fragment key={index}>
                  <div className="col-md-auto">
                    <div
                      className="corporate-profile-imageContainer"
                      key={index}
                    >
                      <img
                        src={statEmp.corpImgManageCorporate}
                        className="corporate-profile-img mb-0"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <h1 className="dashboard-title">
                      {statEmp.corpTItleManageCorporate}
                    </h1>
                    <Link
                      className="btn btn-primary add-new-program-btn me-2"
                      to={`/edit-corporate/${id}`}
                    >
                      Edit Corporate
                    </Link>
                  </div>
                </Fragment>
              ))} */}
              <div className="col-md-auto">
                <div className="corporate-profile-imageContainer">
                  <img
                    src={demographicsData.corporateLogo}
                    className="corporate-profile-img mb-0"
                    alt="img.jpg"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <h1 className="dashboard-title">
                  {demographicsData.corporateName}
                </h1>
                <Link
                  className="btn btn-primary add-new-program-btn me-2"
                  to={`/edit-corporate/${id}`}
                >
                  Edit Corporate
                </Link>
              </div>
              <div className="col-md">
                <CorporatesManageDetails
                  totalMembers={demographicsData.totalActiveUser}
                  totalGroups={demographicsData.totalGroups}
                  totalChallenges={demographicsData.totalChallenges}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div>
              <h1 className="main-title mt-2">Demographics</h1>
            </div>
            <div className="col-md-4">
              <div className="corporate-detail p-3 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="corporate-detail-title">Gender</h1>
                  </div>
                  <div className="col-md-12">
                    <DemographicsGender
                      malePercentage={demographicsData.malePercentage}
                      femalePercentage={demographicsData.femalePercentage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="corporate-detail p-3 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="corporate-detail-title">Age Group</h1>
                  </div>
                  <div className="col-md-12">
                    <DemographicsAgeGroup
                      ag20to30={demographicsData.ag20to30}
                      ag30to40={demographicsData.ag30to40}
                      ag40to50={demographicsData.ag40to50}
                      agabove50={demographicsData.agabove50}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div>
              <h1 className="main-title mt-2">Health Goals</h1>
            </div>
            <div className="col-md-12">
              <div className="corporate-detail p-3 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <HealthGoalsChart corporateId={id} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div>
              <h1 className="main-title mt-2">Daily Target Goals</h1>
            </div>

            <div className="col-md-4">
              <div className="corporate-detail p-3 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="dashboard-corpwell-title mb-0">Steps</h1>
                    <h2 className="dashboard-corpwell-subtitle">
                      Average Steps: <span>{dailytargetGoalsData?.dailyStepsTarget?.avg_steps || 0}</span>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <StepsChart dailyStepsTarget={dailytargetGoalsData?.dailyStepsTarget || {column: [], data: []}} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="corporate-detail p-3 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="dashboard-corpwell-title mb-0">Water</h1>
                    <h2 className="dashboard-corpwell-subtitle">
                      Average Ltr: <span>{dailytargetGoalsData?.dailyWaterTarget?.avg_litres || 0}L</span>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <WaterChart dailyWaterTarget={dailytargetGoalsData?.dailyWaterTarget || {column: [], data: []}} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="corporate-detail p-3 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="dashboard-corpwell-title mb-0">Sleep</h1>
                    <h2 className="dashboard-corpwell-subtitle">
                      Average Sleep: <span>{dailytargetGoalsData?.dailySleepTarget?.avg_sleep || 0}hr</span>
                    </h2>
                  </div>
                  <div className="col-md-12">
                    <SleepChart dailySleepTarget={dailytargetGoalsData?.dailySleepTarget || {column: [], data: []}} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="corporate-dashboard p-3 mb-3">
            <div className="row">
              <div className="col">
                <h1 className="dashboard-corpwell-title">Active Employees</h1>
              </div>
            </div>
            <div className="row">
              <CorporateActiveEmployees corporateId={id} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CorporateDetail;
