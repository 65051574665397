import React, { useState, useEffect } from "react";
import "./BlogPage.css";
import { fetchPublishedBlogs, addComments, fetchBlog, log_data } from "../../../services/cms/Service";

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);  // State to hold blog data
    const [comments, setComments] = useState({});  // State to hold comments per blog
    const [newComment, setNewComment] = useState({});  // State for new comment input for each blog


    console.log(blogs, "blogs")

    // Function to update the post_content for the first blog


    // Function to clean and update the post_content of the first blog
    const updatePostContent = () => {
        if (blogs.length === 0) return; // Check to ensure blogs array isn't empty

        // Clean the post_content for the first blog
        const cleanResponseData = blogs[0].post_content
            .replace(/^```html\n/, '')  // Existing replacement
            .replace(/\r\n/g, '<br/>');

        // Create a copy of the blogs array
        const updatedBlogs = [...blogs];

        // Create a copy of the first blog and update its post_content
        updatedBlogs[0] = {
            ...updatedBlogs[0],           // Copy all properties of the first blog
            post_content: cleanResponseData // Update post_content with cleanResponseData
        };

        // Update the blogs state with the modified array
        setBlogs(updatedBlogs);
    };
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    console.log(id, "id")

    // Handle input changes for each blog's comment form
    const handleInputChange = (e, blogId) => {
        const { name, value } = e.target;
        setNewComment((prevComments) => ({
            ...prevComments,
            [blogId]: {
                ...prevComments[blogId],
                [name]: value,
            }
        }));
    };



    // Submit new comment for each blog
    const handleSubmitComment = (e, blogId) => {
        e.preventDefault();
        const blogComments = comments[blogId] || [];

        const commentToAdd = {
            comment_ID: blogComments.length + 1,
            comment_author: newComment[blogId]?.name || '',
            comment_content: newComment[blogId]?.comment || '',
        };

        // Only allow submission if both fields are filled
        if (commentToAdd.comment_author && commentToAdd.comment_content) {
            setComments((prevComments) => ({
                ...prevComments,
                [blogId]: [...(prevComments[blogId] || []), commentToAdd],
            }));
            postComment(blogId, commentToAdd.comment_author, commentToAdd.comment_content);
            // Clear the comment form after submission
            setNewComment((prevComments) => ({
                ...prevComments,
                [blogId]: { name: "", comment: "" },
            }));
        }
    };

    const postComment = async (blogId, name, comment) => {
        try {
            console.log(blogId, name, comment, "paylod")
            let response = await addComments({ pid: blogId, comment: comment, author: name });
            log_data({ pid: blogId, action: "Commented" })
            if (response.code != 200) {
                throw new Error("Network response was not ok");
            }
            console.log(response.data, "response")
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    // Fetch published blogs
    async function fetchPublishedBlogsData() {
        try {
            let response = await fetchPublishedBlogs();
            if (response.data?.code === 200) {
                const blogsData = response.data.data.articles || [];

                // Set the blogs
                setBlogs(blogsData);

                // Extract comments from blogs and store them separately
                const blogComments = {};
                blogsData.forEach(blog => {
                    blogComments[blog.ID] = blog.comments || [];
                });
                console.log(blogComments, "blogComments")
                setComments(blogComments);
            } else {
                throw new Error("Failed to fetch blogs");
            }
        } catch (e) {
            console.error("Error fetching blogs:", e);
        }
    }

    async function fetch_Blog() {
        try {
            let response = await fetchBlog({ pid: id });
            if (response.data?.code === 200) {
                const blogsData = response.data.data.articles || [];

                // Set the blogs
                setBlogs(blogsData);
                updatePostContent()
                // Extract comments from the blog and store them
                const blogComments = {};
                blogsData.forEach(blog => {
                    blogComments[blog.ID] = blog.comments || [];
                });
                console.log(blogComments, "blogComments")
                setComments(blogComments);
            } else {
                throw new Error("Failed to fetch blog");
            }
        } catch (e) {
            console.error("Error fetching blog:", e);
        }
    }

    useEffect(() => {
        if (id && id != '') {
            fetch_Blog()
            log_data({ pid: id, action: "viewed" })

        } else {
            fetchPublishedBlogsData();
        }

    }, [id]);

    return (
        <div className="blog-page">
            {/* Header */}
            <header className="header">
                <h1>GOQii Blog</h1>
            </header>

            {/* Navbar */}
            <nav className="navbar">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/healthy-living">Healthy Living</a></li>
                    <li><a href="/fitness">Fitness</a></li>
                    <li><a href="/healthy-recipes">Healthy Recipes</a></li>
                    <li><a href="/user-stories">User Stories</a></li>
                    <li><a href="/karma">Karma</a></li>
                    <li><a href="/buy-goqii">Buy GOQii</a></li>
                </ul>
            </nav>


            <div className="blog-container">
                {blogs.map((blog) => (
                    <div key={blog.ID} className="blog-post">
                        {blog.post_type === "library_video" ? (
                            <div className="video-post">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={blog.post_content.match(/src="([^"]+)"/)[1]}  // Extract the video URL from the post_content
                                    title="Video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ) : (
                            <div className="blog-content">
                                <h1>{blog.post_title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: blog.post_content }} />
                            </div>
                        )}

                        {/* Divider */}
                        <hr />

                        {/* Comments Section for each blog */}
                        <div className="comments-section">
                            <h2>Comments</h2>

                            <form className="comment-form" onSubmit={(e) => handleSubmitComment(e, blog.ID)}>
                                <input
                                    type="text"
                                    name="name"
                                    value={newComment[blog.ID]?.name || ""}
                                    onChange={(e) => handleInputChange(e, blog.ID)}
                                    placeholder="Your name"
                                    className="comment-input"
                                    required
                                />
                                <textarea
                                    name="comment"
                                    value={newComment[blog.ID]?.comment || ""}
                                    onChange={(e) => handleInputChange(e, blog.ID)}
                                    placeholder="Your comment"
                                    className="comment-input"
                                    rows="3"
                                    required
                                ></textarea>
                                <button type="submit" className="submit-btn">
                                    Submit Comment
                                </button>
                            </form>

                            {/* Display Comments for each blog */}
                            <div className="comments-list">
                                {comments[blog.ID]?.length > 0 ? (
                                    comments[blog.ID].map((comment, index) => (
                                        <div key={index} className="comment">
                                            <strong>{comment.comment_author}:</strong>
                                            <p>{comment.comment_content}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p>No comments yet.</p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogPage;