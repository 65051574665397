import { Link, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from 'react';
import Navbar from "./pages/componenents/Navbar";
import Sidenavmenubar from "./pages/componenents/Sidenavmenubar";
import { decruptCipherString,decruptCipherJson } from "./pages/utils/CommonLibrary";
import Footer from "./pages/componenents/Footer";


function Protected({ Component, title, childrenProps }) {
    const navigate = useNavigate();
    
    useEffect(() => {
      // console.log('props',path)
        let uid = localStorage.getItem('uid') ? localStorage.getItem('uid') : '';
        if(uid){
            const isLoggedIn = decruptCipherString(uid);
            if (!isLoggedIn) {
              navigate('/login');
            }
        }else{
            navigate('/login');
        }

        // const menuArr = localStorage.getItem('tabs') ? localStorage.getItem('tabs') : '';
        // const menuArrData = decruptCipherJson(menuArr);
        // console.log('menuArrData',menuArrData)

        // const checkPath = menuArrData.some(tabArray => tabArray.path == path);
        // if(!checkPath){
        //   alert('fail');
        // }else{
        //   alert('success');

        // }
      
    }, [navigate]);
  
    if (!localStorage.getItem('uid')) {
      return null;
    }
  
    return (
      <>
        {title != "cnDashboard" && <Sidenavmenubar className="sidenavmenubarStyle" setTitle={title} /> }
        <section className={title == "cnDashboard" ? 'home-section-new' : 'home-section'}>
          <Navbar title={title} />
          <Component {...childrenProps} />
          <Footer />
        </section>
      </>
    );
  }
  
  export default Protected;