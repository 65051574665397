import React, { useEffect, useState } from "react";
import "./CreateChallenges.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { createChallenge, getAllCorporates, getAllTaskLists, getChallenge, getTypes, updateChallenge } from "../../services/corporatewellness/Service";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Select from 'react-select';
import ChallengeTaskList from "./ChallengeTaskList";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #DCDCDC !important",
    padding: "5px 7px",
    borderRadius: "12px !important",
    width: "100% !important",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? 'rgba(59, 130, 246, 1)' // Tailwind's bg-blue-500
      : state.isFocused
      ? 'rgba(59, 130, 246, 0.1)' // bg-blue-100 on focus
      : 'white',
    color: state.isSelected ? 'white' : 'black',
    padding: '0.5rem 1rem', // Tailwind's p-2 px-4
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '0.375rem', // Tailwind's rounded-md
    marginTop: '0.25rem', // Tailwind's mt-1
    backgroundColor: 'white', // Tailwind's bg-white
  }),
  menuList: (base) => ({
    ...base,
    padding: '0', // Tailwind's p-0
  }),
  singleValue: (base) => ({
    ...base,
    color: 'black', // Tailwind's text-black
  }),
};

function CreateChallenges() {
  const [html, setHtml] = useState("");
  const [activityTypes, setActivityTypes] = useState([]);
  const [challengeData, setChallengeData] = useState({
    challengeCorporateId: "",
    challengeTitle: "",
    challengeSubText: "",
    challengeInfoImage: "", // rules image
    challengeImage: "",
    badgeLogo: "",
    challengeStartDate: "",
    challengeEndDate: "",
    challengeVisibleFrom: "",
    challengeActivityType: "",
    expertsTips: "",
    backgroundColor: "",
    faqUrl: "",
  });

  const [challengeDataError, setChallengeDataError] = useState({
    challengeCorporateId: "",
    challengeTitle: "",
    challengeSubText: "",
    challengeInfoImage: "", // rules image
    challengeImage: "",
    badgeLogo: "",
    challengeStartDate: "",
    challengeEndDate: "",
    challengeVisibleFrom: "",
    challengeActivityType: "",
    expertsTips: "",
    backgroundColor: "",
    faqUrl: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rulesImage, setRulesImage] = useState("");
  const [challengeImage, setChallengeImage] = useState("");
  const [badgeLogo, setBadgeLogo] = useState("");
  const [challengeInfoImage, setChallengeInfoImage] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();
  const routeName = useLocation();

  const action = routeName.pathname.includes("edit") ? "edit" : "create";
  const [isLoading, setIsLoading] = useState(false);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [corporatesData, setCorporatesData] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState(null);

  const [taskListsData, setTaskListsData] = useState([
    {
      taskKeyword: "",
      taskText: "",
      target: "",
      points: "",
      priority: 1,
      totalPoints: "",
      displayTarget: "",
      hraData: [
        {
          startDate: "",
          endDate: "",
        },
      ],
    },
    {
      taskKeyword: "",
      taskText: "",
      target: "",
      points: "",
      priority: 2,
      totalPoints: "",
      displayTarget: "",
      hraData: [
        {
          startDate: "",
          endDate: "",
        },
      ],
    }
  ])
  const [allTaskLists, setAllTaskLists] = useState([])
  
  const [videoCsvFile, setVideoCsvFile] = useState(null)
  const [quizCsvFile, setQuizCsvFile] = useState(null)
  const maxSize = 2 * 1024 * 1024; // 2MB max size

  const [customActivityUnits, setCustomActivityUnits] = useState({
    "custom_steps": [
      {
        "custom_activity_label": "km",
        "custom_activity_unit": "km",
      },
      {
        "custom_activity_label": "miles",
        "custom_activity_unit": "miles",
      },
      {
        "custom_activity_label": "step counts",
        "custom_activity_unit": "step_counts",
      },
    ],
    "cycle": [
      {
        "custom_activity_label": "km",
        "custom_activity_unit": "km",
      },
      {
        "custom_activity_label": "miles",
        "custom_activity_unit": "miles",
      },
    ],
    "custom_yoga_challenge": [
      {
        "custom_activity_label": "minutes",
        "custom_activity_unit": "minutes",
      },
      {
        "custom_activity_label": "hours",
        "custom_activity_unit": "hours",
      },
    ],
    "custom_food": [
      {
        "custom_activity_label": "log count",
        "custom_activity_unit": "log_count",
      },
    ],
    "custom_water": [
      {
        "custom_activity_label": "litres",
        "custom_activity_unit": "litres",
      },
    ],
    "custom_activity": [
      {
        "custom_activity_label": "log count",
        "custom_activity_unit": "log_count",
      },
    ],
    "guided_meditation": [
      {
        "custom_activity_label": "minutes",
        "custom_activity_unit": "minutes",
      },
      {
        "custom_activity_label": "hours",
        "custom_activity_unit": "hours",
      },
    ],
  })

  useEffect(() => {
    fetchTypes();
    fetchCorporates();
    fetchAllTaskLists();
  }, []);

  useEffect(() => {
    if (challengeData.challengeVisibleFrom && 
        (new Date(challengeData.challengeVisibleFrom) < new Date(challengeData.challengeStartDate) || 
        new Date(challengeData.challengeVisibleFrom) > new Date(challengeData.challengeEndDate))) {
      
      setChallengeDataError((prev) => ({
        ...prev,
        challengeVisibleFrom: "Visible from should be greater than Start Date & less than End Date."
      }));
      
      setChallengeData((prev) => ({
        ...prev,
        challengeVisibleFrom: "",
      }));
    }
  }, [challengeData.challengeVisibleFrom, challengeData.challengeStartDate, challengeData.challengeEndDate]);

  useEffect(() => {
    if (action === 'edit') {
      fetchChallengeData(id);
    }
  }, [id])

  useEffect(() => {
    if (action === 'edit') {
      if (corporatesData.length > 0 && challengeData) {      
        const matchedOption = corporatesData.find(option => option.value === challengeData.challengeCorporateId);
  
        if (matchedOption) {
          setSelectedCorporate(matchedOption);
        }
      }
    }
  }, [corporatesData, challengeData]);

  const fetchChallengeData = async (id) => {
    setIsLoading(true);
    try {
        const payload = {
          challengeId: id
        }
        const response = await getChallenge(payload);

        if (response.data.status === 200) {
          const data = response.data.data;
          let finalData = {
            challengeCorporateId: data.challengeCorporateId,
            challengeId: data.challengeId,
            challengeTitle: data.challengeTitle,
            challengeSubText: data.challengeSubText,
            challengeInfoImage: data.challengeInfoImage, // rules image
            challengeImage: data.challengeImageV3,
            badgeLogo: data.badgeLogo,
            challengeStartDate: data.challengeStartsOn,
            challengeEndDate: data.challengeEndsOn,
            challengeVisibleFrom: moment(data.challengeVisibleFrom).format("YYYY-MM-DD"),
            challengeActivityType: data.challengeActivityType,
            expertsTips: data.expertsTips,
            backgroundColor: data.backgroundColor,
            faqUrl: data.faqurl,
          };

          if (finalData.challengeActivityType !== "taskList" && finalData.challengeActivityType !== "steps" && finalData.challengeActivityType !== "yoga_challenge") {
            finalData = { 
              ...finalData, 
              unit: data.unit,
              unitValue: data.unitValue, 
            };
          }
          setChallengeData(finalData);
          setHtml(data.expertsTips);

          if (data.challengeActivityType === "taskList") {
            setTaskListsData(JSON.parse(data.tasks));
          }
        }

        setIsLoading(false);
    } catch(e) {
        setIsLoading(false);
        console.log(e);
    }
  }

  function onChange(content) {
    setHtml(content);
    setChallengeData((prev) => ({
      ...prev,
      expertsTips: content,
    }));

    if (content === "<p><br></p>") {
      setChallengeDataError((prev) => ({
        ...prev,
        expertsTips: "This is required.",
      }));
    } else {
      setChallengeDataError((prev) => ({
        ...prev,
        expertsTips: "",
      }));
    }
  }

  const fetchTypes = async () => {
    try {
      const res = await getTypes("123456");

      if (res.data.status === 200) {
        setActivityTypes(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchCorporates = async () => {
    try {
      const res = await getAllCorporates(-1);

      if (res.data.status === 200) {
        const data = res.data.data.map(d => ({
          value: d.corporateId,
          label: `${d.corporateName} - ${d.corporateId}`
        }));
        setCorporatesData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchAllTaskLists = async () => {
    try {
      const res = await getAllTaskLists();

      if (res.data.status === 200) {
        setAllTaskLists(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    },
  };

  const editorWrapperStyle = {
    height: "160px",
    marginBottom: "40px",
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;

    if (name === "unitValue") {
      if (!/^\d*$/.test(val)) {
        return;
      }
    }

    setChallengeData((prev) => ({
      ...prev,
      [name]: val,
    }));

    if (val === "") {
      setChallengeDataError((prev) => ({
        ...prev,
        [name]: "This is required field."
      }));
    } else {

      setChallengeDataError((prev) => ({
        ...prev,
        [name]: ""
      }));

      if (name === "backgroundColor") {
        if ((val.length === 4 || val.length === 7) && !/^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(val)) {
          // alert("Please enter a valid hex color value");
          setChallengeDataError((prev) => ({
            ...prev,
            "backgroundColor": "Please enter a valid hex color value."
          }));
          setChallengeData((prev) => ({
            ...prev,
            "backgroundColor": "",
          }));
        } else if (val.length > 7) {
          // alert("Hex color value cannot exceed 7 characters");
          setChallengeDataError((prev) => ({
            ...prev,
            "backgroundColor": "Hex color value cannot exceed 7 characters."
          }));
          setChallengeData((prev) => ({
            ...prev,
            "backgroundColor": "",
          }));
        }
      }
    }
  };

  const handleDateChange = (e, type) => {
    const val = e.target.value;

    setChallengeData((prev) => ({
      ...prev,
      [type]: val,
    }));

    if (val === "") {
      setChallengeDataError((prev) => ({
        ...prev,
        [type]: "This is required field."
      }));
    } else {

      setChallengeDataError((prev) => ({
        ...prev,
        [type]: ""
      }));

      if (type === "challengeEndDate") {
        setChallengeDataError((prev) => ({
          ...prev,
          challengeVisibleFrom: ""
        }));
        if (new Date(val) < new Date(challengeData.challengeStartDate)) {
          // alert("End date cannot be before the start date.");
          setChallengeDataError((prev) => ({
            ...prev,
            challengeEndDate: "End date cannot be before the start date."
          }));
          setChallengeData((prev) => ({
            ...prev,
            challengeEndDate: "",
          }));
        }
      } else if (type === "challengeStartDate") {
        setChallengeDataError((prev) => ({
          ...prev,
          challengeVisibleFrom: ""
        }));
        const endDateInput = document.getElementById('challengeEndDate');
        if (endDateInput) {
          endDateInput.setAttribute('min', val);
        }
  
        if (challengeData.challengeEndDate && new Date(challengeData.challengeEndDate) < new Date(val)) {
          // alert("End date should be greater than or equal to start date.");
          setChallengeDataError((prev) => ({
            ...prev,
            challengeEndDate: "End date should be greater than or equal to start date."
          }));
          setChallengeData((prev) => ({
            ...prev,
            challengeEndDate: "",
          }));
        }
      } else if (type === "challengeVisibleFrom") {
        if (!challengeData.challengeStartDate || !challengeData.challengeEndDate) {
          setChallengeDataError((prev) => ({
            ...prev,
            challengeVisibleFrom: "Please enter Start Date & End Date."
          }));
          setChallengeData((prev) => ({
            ...prev,
            challengeVisibleFrom: "",
          }));
        }
      }
    }
  };

  const handleFileChange = (e, type, i = 0) => {
    const fileInput = e.target;
    const file = fileInput.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;  
  
        img.onload = () => {
          if (type === "hraImage" || type === "challengeInfoImage") {
            if ((img.width >= 750 && img.width <= 850) || (img.height >= 150 && img.height <= 250)) {
              if (type === "hraImage") {
                const updatedData = taskListsData.map((task, index) => {
                  return index === i ? { ...task, [type]: file } : task;
                });
                setTaskListsData(updatedData);
              } else {
                setChallengeData((prevData) => ({ ...prevData, [type]: file }));
              }
    
              setChallengeDataError((prev) => ({
                ...prev,
                [type]: ""
              }));
            } else {
              toast.error("Image dimensions must be 800x200.");
              fileInput.value = "";
            }
          } else {
            setChallengeData((prevData) => ({ ...prevData, [type]: file }));

            setChallengeDataError((prev) => ({
              ...prev,
              [type]: ""
            }));
          }
        };
  
        img.onerror = () => {
          toast.error("Failed to load the image. Please try a different file.");
          fileInput.value = "";
        };
      };
  
      reader.readAsDataURL(file); 
    } else {
      toast.error("Please select a valid image file.");
      fileInput.value = "";
    }
  };
  

  const handleCorporateSelectChange = (selectedOption) => {    
    if (selectedOption) {
      setChallengeData((prev) => ({
        ...prev,
        challengeCorporateId: selectedOption.value
      }));
      setChallengeDataError((prev) => ({
        ...prev,
        challengeCorporateId: ""
      }));
    } else {
      setChallengeDataError((prev) => ({
        ...prev,
        challengeCorporateId: "Please select a corporate."
      }));
      setChallengeData((prev) => ({
        ...prev,
        challengeCorporateId: ""
      }));
    }

    setSelectedCorporate(selectedOption);
  }

  const handleAddTask = () => {
    const tempData = [
      ...taskListsData,
      {
        taskKeyword: "",
        taskText: "",
        target: "",
        points: "",
        priority: taskListsData.length + 1,
        totalPoints: "",
        // displayTarget: "",
        subTitle: "",
        hraData: [
          {
            startDate: "",
            endDate: "",
          },
        ],
      }
    ];

    setTaskListsData(tempData);
  };

  const handleRemoveTask = (index) => {
    const tempData = taskListsData.filter((d, i) => i !== index);
    setTaskListsData(tempData);
  };

  const handleTaskInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name !== "taskKeyword" && name !== "subTitle") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }

    if (name === "taskKeyword" && taskListsData.some(t => t.taskKeyword === value)) {
      toast.error("Same task cannot be created.");
      return;
    }
  
    setTaskListsData((prevTasks) => {
      return prevTasks.map((task, i) => {
        let updatedTask = {};

        if (i === index) {
          if (name === "taskKeyword") {
            if (
              value === "activityLog" || 
              value === "trackerSleep" || 
              value === "syncTracker" || 
              value === "dailyBonus" || 
              value === "yogaChallenge" ||
              value === "Quiz" ||
              value === "meditation"
            ) {
              if (value === "syncTracker") {
                updatedTask = { 
                  ...task, 
                  [name]: value, 
                  "taskText": allTaskLists.find(a => a.taskKeyword === value).taskText,
                  // "displayTarget": "0",
                  "totalPoints": "0",
                };
              } else if (value === "Quiz") {
                updatedTask = { 
                  ...task, 
                  [name]: value, 
                  "taskText": allTaskLists.find(a => a.taskKeyword === value).taskText,
                  "points": "2",
                };
              } else {
                updatedTask = { 
                  ...task, 
                  [name]: value, 
                  "taskText": allTaskLists.find(a => a.taskKeyword === value).taskText,
                  // "displayTarget": "0",
                };
              }
            } else {
              updatedTask = { 
                ...task, 
                [name]: value, 
                "taskText": allTaskLists.find(a => a.taskKeyword === value).taskText,
              };
            }
          } else if (name === "points") {
            updatedTask = { 
              ...task, 
              [name]: value,
              "totalPoints": (task.taskKeyword === "activityLog" || task.taskKeyword === "trackerSleep" || task.taskKeyword === "dailyBonus" || 
              task.taskKeyword === "yogaChallenge" || task.taskKeyword === "meditation") ? value : task.totalPoints,
            };
          } else {
            updatedTask = { 
              ...task, 
              [name]: value 
            };
          }
        } else {
          updatedTask = task;
        }

        return updatedTask;
      });
    });

    // if (val === "") {
    //   setChallengeDataError((prev) => ({
    //     ...prev,
    //     [name]: "This is required field."
    //   }));
    // } else {
    //   setChallengeDataError((prev) => ({
    //     ...prev,
    //     [name]: ""
    //   }));
    // }
  }

  const handleAddHra = (index) => {
    setTaskListsData(prevData => {
      const updatedData = [...prevData];
      updatedData[index].hraData.push({ startDate: "", endDate: "" });
      return updatedData;
    });
  };

  const handleRemoveHra= (index, hraIndex) => {
    const tempData = taskListsData[index].hraData.filter((_, i) => i !== hraIndex);
    setTaskListsData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        hraData: tempData,
      };
      return updatedData;
    });
  };

  const handleHraInputChange = (e, index, hraIndex) => {
    const { name, value } = e.target;   
    
    const hraStartDate = taskListsData[index].hraData[hraIndex].startDate;
    const hraEndDate = taskListsData[index].hraData[hraIndex].endDate;

    if (name === "endDate" && value !== "") {
      if (new Date(hraStartDate) >= new Date(value)) {
        toast.error("End date cannot be lesser than Start Date");
        return;
      }
    } else if (name === "startDate" && value !== "") {
      if (new Date(hraEndDate) <= new Date(value)) {
        toast.error("Start date cannot be greater than End Date");
        return;
      }
    }

    if (taskListsData[index].hraData.length > 1 && hraIndex > 0) {
      // const prevHraStartDate = taskListsData[index].hraData[hraIndex - 1].startDate;
      const prevHraEndDate = taskListsData[index].hraData[hraIndex - 1].endDate;
      if (new Date(prevHraEndDate) >= new Date(value)) {
        toast.error("Start date cannot be lesser than Previous's End Date");
        return;
      }
    }
  
    setTaskListsData((prevTasks) => {
      return prevTasks.map((task, i) => {
        if (i === index) {
          const updatedData = task.hraData.map((data, dIndex) => 
            dIndex === hraIndex
              ? { ...data, [name]: value } 
              : data
          );

          return {...task, hraData: updatedData};
        }

        return task;
      });
    });

    // if (val === "") {
    //   setChallengeDataError((prev) => ({
    //     ...prev,
    //     [name]: "This is required field."
    //   }));
    // } else {
    //   setChallengeDataError((prev) => ({
    //     ...prev,
    //     [name]: ""
    //   }));
    // }
  }

  const handleCsvFileChange = (e, type, index) => {
    const selectedFile = e.target.files[0];

    if (selectedFile){
      if (selectedFile.type !== 'text/csv') {
        toast.error('Please upload a CSV file.');
        return;
      }

      if (selectedFile.size > maxSize) {
        toast.error('File is too large. Maximum size is 2MB.');
        return;
      }

      // if (type === "videoCsv") {
      //   setVideoCsvFile(selectedFile);
        
      // } else if (type === "quizCsv") {
      //   setQuizCsvFile(selectedFile);
      // }
      setTaskListsData((prevTasks) => {
        return prevTasks.map((task, i) => {
          return i === index ? {...task, [type]: selectedFile } : task;
        });
      });
    }
  };

  console.log(taskListsData);
  

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // await new Promise((resolve) =>
      //   setTimeout(() => {
      //     console.log(challengeData);
      //     resolve();
      //   }, 2000)
      // );

      if (
        Object.values(challengeData).every(d => d === "") || 
        Object.values(challengeData).some(d => d === "") || 
        Object.values(challengeDataError).some(d => d !== "")
      ) {
        const updatedErrors = Object.keys(challengeDataError).reduce((acc, key) => {
          acc[key] = challengeData[key] === "" ? "This is required field." : "";
          return acc;
        }, {});
      
        setChallengeDataError(updatedErrors);
        return;
      } else if (challengeData.challengeActivityType === "taskList" && 
        taskListsData.some(t => 
          t.taskKeyword === "" ||
          t.taskText === "" ||
          t.points === "" ||
          t.totalPoints === "" ||
          t.target === "" ||
          // t.displayTarget === "" ||
          t.subTitle === "" ||
          (t.taskKeyword.toLowerCase().includes("hra") && (t.hraData.some(h => 
            h.startDate === "" ||
            h.endDate === ""
          // ) || (!t.hraImage || t.hraImage === null || (!t.hraImage.includes("http") && !(t.hraImage instanceof File))))) || 
          ) || (!t.hraImage || t.hraImage === null))) || 
          (t.taskKeyword.toLowerCase().includes("yogachallenge") && (!t.videoType || t.videoType === "")) ||
          ((action === "edit") && t.taskKeyword.toLowerCase().includes("yogachallenge") && t.videoType === "specific" && (!t.id && (!t.videoCsv || t.videoCsv === null))) ||
          ((action === "edit") && t.taskKeyword.toLowerCase().includes("quiz") && (!t.id && (!t.quizCsv || t.quizCsv === null)))
        )
      ) {
        toast.error("Some tasks data is empty.");
        return;
      } else if ((challengeData.challengeActivityType !== "taskList" && challengeData.challengeActivityType !== "steps" && challengeData.challengeActivityType !== "yoga_challenge") && 
        ((!challengeData.unit || challengeData.unit === "") || (!challengeData.unitValue || challengeData.unitValue === ""))
      ) {
        toast.error("Activity Unit & it's value cannot be empty.");
        return;
      }

      const formData = new FormData();
      
      formData.append('formData', JSON.stringify(challengeData));
      if (challengeData.challengeActivityType === "taskList") {
        formData.append('formTaskData', JSON.stringify(taskListsData));
      }
      
      if (challengeData.badgeLogo !== "" && challengeData.badgeLogo instanceof File) {
        formData.append('badgeLogo', challengeData.badgeLogo);
      } else {
        formData.append('badgeLogo', new File([], "empty.jpg"));
      }

      if (challengeData.challengeInfoImage !== "" && challengeData.challengeInfoImage instanceof File) {
        formData.append('challengeInfoImage', challengeData.challengeInfoImage);
      } else {
        formData.append('challengeInfoImage', new File([], "empty.jpg"));
      }

      if (challengeData.challengeImage !== "" && challengeData.challengeImage instanceof File) {
        formData.append('challengeImage', challengeData.challengeImage);
      } else {
        formData.append('challengeImage', new File([], "empty.jpg"));
      }
            
      const videoTask = taskListsData.find(t => t.taskKeyword === "yogaChallenge");
      if (videoTask && videoTask.videoType !== "" && videoTask.videoCsv instanceof File) {
        formData.append('videoCsvFile', videoTask.videoCsv);
      }
      
      const quizTask = taskListsData.find(t => t.taskKeyword === "Quiz");
      if (quizTask && quizTask.quizCsv instanceof File) {
        formData.append('quizCsvFile', quizTask.quizCsv);
      }
      
      const hraTask = taskListsData.find(t => t.taskKeyword === "HRA");
      if (hraTask && hraTask.hraImage instanceof File) {
        formData.append('hraImage', hraTask.hraImage);
      }
      
      if (action === "edit") {
        await updateChallenge(formData)
        .then((res) => {
            if (res.data.status === 200) {
                toast.success(res.data.message, {
                    position: 'top-right',
                    autoClose: 1000,
                    onClose: () => {
                      setIsSubmitting(false);
                      navigate("/corporate-challenges");
                    }
                });                                
            } else {
                if (res.data.status === 403) {
                    setIsSubmitting(false);
                }
                toast.error(res.data.message, {
                  position: 'top-right',
                  autoClose: 1000,
                });   
            }              
        })
        .catch((err) => {
            // toast.error(err.data.message, {
            //   position: 'top-right',
            //   autoClose: 1000,
            // });  
            console.log(err);
        });
      } else {
        await createChallenge(formData)
          .then((res) => {
              if (res.data.status === 200) {
                  toast.success(res.data.message, {
                      position: 'top-right',
                      autoClose: 1000,
                      onClose: () => {
                        setIsSubmitting(false);
                        navigate("/corporate-challenges");
                      }
                  });                                
              } else {
                  if (res.data.status === 403) {
                      setIsSubmitting(false);
                  }
                  toast.error(res.data.message, {
                    position: 'top-right',
                    autoClose: 1000,
                  });   
              }              
          })
          .catch((err) => {
              // toast.error(err.data.message, {
              //   position: 'top-right',
              //   autoClose: 1000,
              // });  
              console.log(err);
              
          });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log(challengeData);
  console.log(challengeDataError);

  return (
    <>
      <ToastContainer />
      <section className="CreateChallenges">
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md" style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <button className="btn btn-primary back-btn mb-0 me-3" onClick={() => navigate('/corporate-challenges')}><i className="bi bi-chevron-left me-1"></i> Back</button>
              <h1 className="main-title" style={{ margin: '0' }}>{action === "edit" ? "Edit" : "Create"} Challenge</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className="create-challenges mb-3">
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img
                  src="https://appcdn.goqii.com/storeimg/15311_1726123075.png"
                  alt="Invited Members icon"
                  style={{ height: "140px" }}
                  className="img-fluid mb-0"
                />
              </div>
              <div className="col-md">
                <p className="mb-0">
                  Create Challenge enables the creation and management of customized health and wellness challenges designed to motivate and engage employees within 
                  their corporate environment. This user-friendly form allows administrators to set up and tailor challenges that align with corporate wellness goals, 
                  fostering a dynamic and health-conscious workplace.
                </p>
              </div>
            </div>
          </div>

          <div className="create-challenges p-4">
            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">Corporate</label>
                <Select
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  name="color"
                  options={corporatesData}
                  value={selectedCorporate}
                  onChange={handleCorporateSelectChange}
                  styles={customStyles}
                />
                {challengeDataError.challengeCorporateId && <div className="text-danger">{challengeDataError.challengeCorporateId}</div>}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">Title</label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter Challenge Title"
                  name="challengeTitle"
                  value={challengeData.challengeTitle}
                  onChange={handleInputChange}
                />
                {challengeDataError.challengeTitle && <div className="text-danger">{challengeDataError.challengeTitle}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">Sub Text</label>
                <textarea
                  type="text"
                  className="form-control input-field-ui"
                  rows="3"
                  id=""
                  placeholder="Message…"
                  name="challengeSubText"
                  value={challengeData.challengeSubText}
                  onChange={handleInputChange}
                ></textarea>
                {challengeDataError.challengeSubText && <div className="text-danger">{challengeDataError.challengeSubText}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                  <label className="form-label sub-title">Rules Image</label>
                  <div className="col pe-1">
                    <input
                      type="file"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Enter Challenge Title"
                      onChange={(e) =>
                        handleFileChange(e, "challengeInfoImage")
                      }
                    />
                  </div>
                  <div className="form-text">Image dimensions should be close to 800x200</div>
                  {/* <div className="col-auto ps-0">
                    <button
                      type="button"
                      id="xlsxSubmit"
                      className="btn btn-success uplaod-file-btn"
                    >
                      Upload File
                    </button>
                  </div> */}
                </div>
                {challengeDataError.challengeInfoImage && <div className="text-danger">{challengeDataError.challengeTitle}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                  <label className="form-label sub-title">
                    Challenge Image
                  </label>
                  <div className="col pe-1">
                    <input
                      type="file"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Enter Challenge Title"
                      onChange={(e) => handleFileChange(e, "challengeImage")}
                    />
                  </div>
                  {/* <div className="col-auto ps-0">
                    <button
                      type="button"
                      id="xlsxSubmit"
                      className="btn btn-success uplaod-file-btn"
                    >
                      Upload File
                    </button>
                  </div> */}
                </div>
                {challengeDataError.challengeImage && <div className="text-danger">{challengeDataError.challengeImage}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                  <label className="form-label sub-title">Badge Logo</label>
                  <div className="col pe-1">
                    <input
                      type="file"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Enter Challenge Title"
                      onChange={(e) => handleFileChange(e, "badgeLogo")}
                    />
                  </div>
                  {/* <div className="col-auto ps-0">
                    <button
                      type="button"
                      id="xlsxSubmit"
                      className="btn btn-success uplaod-file-btn"
                    >
                      Upload File
                    </button>
                  </div> */}
                </div>
                {challengeDataError.badgeLogo && <div className="text-danger">{challengeDataError.badgeLogo}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md">
                    <label className="form-label sub-title">Start Date</label>
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Start Date"
                      name="challengeStartDate"
                      value={challengeData.challengeStartDate}
                      onChange={(e) =>
                        handleDateChange(e, "challengeStartDate")
                      }
                    />
                    {challengeDataError.challengeStartDate && <div className="text-danger">{challengeDataError.challengeStartDate}</div>}
                  </div>
                  <div className="col-md">
                    <label className="form-label sub-title">End Date</label>
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="End Date"
                      name="challengeEndDate"
                      value={challengeData.challengeEndDate}
                      onChange={(e) => handleDateChange(e, "challengeEndDate")}
                      min={challengeData.challengeStartDate || ""}
                    />
                    {challengeDataError.challengeEndDate && <div className="text-danger">{challengeDataError.challengeEndDate}</div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">Visible From</label>
                <input
                  type="date"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Visible From"
                  name="challengeVisibleFrom"
                  value={challengeData.challengeVisibleFrom}
                  onChange={(e) =>
                    handleDateChange(e, "challengeVisibleFrom")
                  }
                />
                {challengeDataError.challengeVisibleFrom && <div className="text-danger">{challengeDataError.challengeVisibleFrom}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">
                  Challenge Activity type
                </label>
                <select
                  className="form-select input-field-ui "
                  id=""
                  aria-label="Default select example"
                  name="challengeActivityType"
                  value={challengeData.challengeActivityType}
                  onChange={handleInputChange}
                  disabled={action === "edit"}
                >
                  <option value="">Select Activity</option>
                  {/* {activityTypes.map((val, i) => {
                    return (
                      <option key={val + i} value={val}>
                        {val}
                      </option>
                    );
                  })} */}
                  <option value="steps">Monthly Steps</option>
                  <option value="custom_steps">Custom Steps</option>
                  <option value="cycle">Cycle</option>
                  {/* <option value="yoga_challenge">Meditation</option> */}
                  <option value="guided_meditation">Guided Meditation</option>
                  <option value="custom_food">Food</option>
                  <option value="custom_water">Water</option>
                  <option value="custom_activity">Manual Activity</option>
                  <option value="taskList">Task List</option>
                </select>
                {challengeDataError.challengeActivityType && <div className="text-danger">{challengeDataError.challengeActivityType}</div>}
              </div>
            </div>

            {challengeData.challengeActivityType && (challengeData.challengeActivityType !== "taskList" && challengeData.challengeActivityType !== "steps" && challengeData.challengeActivityType !== "yoga_challenge") &&
            <div className="row mb-4">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md">
                    <label className="form-label sub-title">Unit Value</label>
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id=""
                      placeholder="Unit Value"
                      name="unitValue"
                      value={challengeData.unitValue || ""}
                      onChange={handleInputChange}
                    />
                    {challengeDataError.unitValue && <div className="text-danger">{challengeDataError.unitValue}</div>}
                  </div>
                  <div className="col-md">
                    <label className="form-label sub-title">
                      Unit
                    </label>
                    <select
                      className="form-select input-field-ui "
                      id=""
                      aria-label="Default select example"
                      name="unit"
                      value={challengeData.unit || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Unit</option>
                      {customActivityUnits[challengeData.challengeActivityType] && customActivityUnits[challengeData.challengeActivityType].map((val, i) => {
                        return (
                          <option key={val + i} value={val.custom_activity_unit}>
                            {val.custom_activity_label}
                          </option>
                        );
                      })}
                    </select>
                    {challengeDataError.unit && <div className="text-danger">{challengeDataError.unit}</div>}
                  </div>
                </div>
              </div>
            </div>
            }

            {challengeData.challengeActivityType === "taskList" &&
            <div className="col mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">Select Task</label>
              </div>
              {taskListsData.map((val, i) => (
                <div key={i} className="d-flex align-items-center mb-2">
                  <div className="col-md-auto tw-mr-4">
                    <label className="form-label sub-title mb-0">{i+1}</label>
                  </div>
                  <ChallengeTaskList
                    recordNum={i}
                    singleTaskData={val}
                    taskListsData={taskListsData}
                    allTaskLists={allTaskLists}
                    handleTaskInputChange={(e) => handleTaskInputChange(e, i)}
                    handleAddHra={() => handleAddHra(i)}
                    handleRemoveHra={(hraIndex) => handleRemoveHra(i, hraIndex)}
                    handleHraInputChange={(e, hraIndex) => handleHraInputChange(e, i, hraIndex)}
                    handleCsvFileChange={(e, type) => handleCsvFileChange(e, type, i)}
                    setTaskListsData={setTaskListsData}
                    action={action}
                    handleFileChange={(e) => handleFileChange(e, "hraImage", i)}
                  />
                  {taskListsData.length > 2 && i > 1 && 
                    <div className="col-md-1">
                      <button className="btn text-danger" onClick={() => handleRemoveTask(i)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </div>
                  }
                </div>
              ))}

              {/* <div className="col-md-7 mb-2">
                <div className="row align-items-center">
                  <div className="col-md-auto">
                    <label className="form-label sub-title mb-0">1.</label>
                  </div>
                  <div className="col-md-4 ps-0">
                    <select
                      className="form-select input-field-ui "
                      id=""
                      name=""
                      aria-label="Default select example"
                    >
                      <option value="">Select Task</option>
                      {allTaskLists.map((val, i) => {
                        return (
                          <option key={val + i} value={val.taskKeyword}>
                            {val.taskText}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-auto pe-2">
                    <label className="form-label mb-0">Logs</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-auto pe-2">
                    <label className="form-label mb-0">Points</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-1">
                    <button className="btn text-danger">
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-7 mb-2">
                <div className="row align-items-center">
                  <div className="col-md-auto">
                    <label className="form-label sub-title mb-0">2.</label>
                  </div>
                  <div className="col-md-4 ps-0">
                    <select
                      className="form-select input-field-ui "
                      id=""
                      name=""
                      aria-label="Default select example"
                    >
                      <option value="">Select Task</option>
                      <option value="1">Food</option>
                      <option value="2">Steps</option>
                      <option value="3">Quiz</option>
                    </select>
                  </div>
                  <div className="col-md-auto pe-2">
                    <label className="form-label mb-0">Logs</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-auto pe-2">
                    <label className="form-label mb-0">Points</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-1">
                    <button className="btn text-danger">
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div> */}

              <div className="col-md-7">
                <button
                  type="button"
                  className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  onClick={handleAddTask}
                >
                  <i className="bx bxs-plus-circle"></i> Add Task
                </button>
              </div>
            </div>}

            {/* <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title mb-0">Take HRA</label>{" "}
                <br />
                <label className="form-label">
                  Total number of HRA throughout the challenge.
                </label>
              </div>

              <div className="col-md-7 mb-2">
                <div className="row align-items-center">
                  <div className="col-md-auto">
                    <label className="form-label sub-title mb-0">1.</label>
                  </div>
                  <div className="col-md-auto ps-0 pe-2">
                    <label className="form-label mb-0">Start Date</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-auto pe-2">
                    <label className="form-label mb-0">End Date</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-1">
                    <button className="btn text-danger">
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-7 mb-2">
                <div className="row align-items-center">
                  <div className="col-md-auto">
                    <label className="form-label sub-title mb-0">2.</label>
                  </div>
                  <div className="col-md-auto ps-0 pe-2">
                    <label className="form-label mb-0">Start Date</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-auto pe-2">
                    <label className="form-label mb-0">End Date</label>
                  </div>
                  <div className="col-md ps-0">
                    <input
                      type="date"
                      className="form-control input-field-ui"
                      id=""
                    />
                  </div>
                  <div className="col-md-1">
                    <button className="btn text-danger">
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <a
                  type="button"
                  className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  <i className="bx bxs-plus-circle"></i> Add HRA
                </a>
              </div>
            </div> */}

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">Experts Tips</label>
                <div style={editorWrapperStyle}>
                  <ReactQuill
                    value={html}
                    onChange={onChange}
                    modules={modules}
                    className="form-control input-field-ui p-0"
                    placeholder="Text"
                  />
                </div>
                {challengeDataError.expertsTips && <div className="text-danger">{challengeDataError.expertsTips}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">
                  Background Colour
                </label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="#000000"
                  name="backgroundColor"
                  value={challengeData.backgroundColor}
                  onChange={handleInputChange}
                  // minLength={4}
                  // maxLength={7}
                />
                {challengeDataError.backgroundColor && <div className="text-danger">{challengeDataError.backgroundColor}</div>}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-7">
                <label className="form-label sub-title">FAQ URL</label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id=""
                  placeholder="Enter URL..."
                  name="faqUrl"
                  value={challengeData.faqUrl}
                  onChange={handleInputChange}
                />
              </div>
              {challengeDataError.faqUrl && <div className="text-danger">{challengeDataError.faqUrl}</div>}
            </div>

            <div className="row">
              <div className="col-md-7">
                <button
                  type="button"
                  className="btn program-save-btn me-2"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>{" "}
                <button type="button" className="btn clear-btn">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateChallenges;
